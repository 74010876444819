import { motion } from "framer-motion";
import styled from "styled-components";
import { typeScale, stripeTheme } from "../../themes";

export const Background = styled.div`
  width: 100vw;
`;

export const ColoredSection = styled(motion.section)`
  height: calc(100vh - 40px);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Title = styled.h1`
  font-size: ${typeScale(10)};
  color: ${stripeTheme.grey[20]};

  @media only screen and (max-width: 768px) {
    font-size: ${typeScale(6)};
  }

  @media only screen and (max-width: 480px) {
    font-size: ${typeScale(4)};
  }
`;

export const MainTitle = styled(motion.h1)`
  font-size: ${typeScale(11)};
  margin: 20px 48px 0;
  color: ${stripeTheme.grey[20]};

  @media only screen and (max-width: 768px) {
    font-size: ${typeScale(7)};
  }

  @media only screen and (max-width: 480px) {
    font-size: ${typeScale(5)};
    text-align: center;
  }
`;

export const RegularSection = styled.section`
  width: 1440px;
  max-width: 100%;
  margin: 0 auto;
  padding: 240px 48px 320px;
  position: relative;

  @media only screen and (max-width: 768px) {
    width: 100%;
    padding: 120px 48px 160px;
  }

  @media only screen and (max-width: 480px) {
    width: 100%;
    padding: 80px 40px 100px;
  }
`;

export const HiddenRefDiv = styled.div`
  position: absolute;
  top: 120px;
`;

export const SubTitle = styled.h3`
  margin-bottom: 80px;
  font-size: ${typeScale(9)};
  font-weight: 500;

  @media only screen and (max-width: 768px) {
    font-size: ${typeScale(5)};
    margin-bottom: 40px;
  }

  @media only screen and (max-width: 480px) {
    font-size: ${typeScale(3)};
    margin-bottom: 24px;
  }
`;

export const DescriptionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 48px;
  flex-wrap: wrap;

  @media only screen and (max-width: 768px) {
    gap: 0px;
  }

  @media only screen and (max-width: 480px) {
    gap: 0px;
  }
`;

export const Description = styled.div`
  font-size: ${typeScale(2)};
  margin-bottom: 32px;
  width: 720px;

  @media only screen and (max-width: 768px) {
    font-size: ${typeScale(0)};
    width: 100%;
    margin-bottom: 24px;
  }

  @media only screen and (max-width: 480px) {
    font-size: ${typeScale(-1)};
    width: 100%;
    margin-bottom: 16px;
  }
`;

export const CategoryList = styled.div`
  font-size: ${typeScale(2)};
  margin-bottom: 32px;

  @media only screen and (max-width: 768px) {
    font-size: ${typeScale(0)};
    margin-bottom: 24px;
  }

  @media only screen and (max-width: 480px) {
    font-size: ${typeScale(-1)};
  }
`;

export const Category = styled.div`
  font-size: ${typeScale(-1)};
  margin-bottom: 16px;
  font-weight: 600;

  @media only screen and (max-width: 768px) {
    font-size: ${typeScale(-2)};
    margin-bottom: 12px;
  }

  @media only screen and (max-width: 480px) {
    font-size: ${typeScale(-3)};
  }
`;

export const ApproachWrapper = styled.div`
  margin-bottom: 48px;

  @media only screen and (max-width: 768px) {
    margin-bottom: 24px;
  }
`;
export const ApproachHeader = styled.h4`
  font-size: ${typeScale(3)};
  font-weight: 600;
  margin-top: 0;

  @media only screen and (max-width: 768px) {
    font-size: ${typeScale(1)};
  }

  @media only screen and (max-width: 480px) {
    font-size: ${typeScale(0)};
  }
`;

export const GenericText = styled.div`
  font-size: ${typeScale(0)};

  @media only screen and (max-width: 768px) {
    font-size: ${typeScale(-1)};
  }
`;

export const ResultsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 32px;
  margin-bottom: 32px;

  @media only screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    gap: 48px;
    margin-bottom: 48px;
  }
`;

export const ResultHeader = styled.div`
  font-size: ${typeScale(-1)};
  margin-bottom: 32px;
  font-weight: 600;

  @media only screen and (max-width: 768px) {
    font-size: ${typeScale(-2)};
    margin-bottom: 16px;
  }

  @media only screen and (max-width: 480px) {
    font-size: ${typeScale(-2)};
    margin-bottom: 16px;
  }
`;

export const ResultDescription = styled.div`
  font-size: ${typeScale(2)};
  margin-top: 0;

  @media only screen and (max-width: 768px) {
    font-size: ${typeScale(1)};
  }

  @media only screen and (max-width: 480px) {
    font-size: ${typeScale(0)};
  }
`;

export const EndSection = styled.section`
  max-width: 100%;
  margin: 0 auto;
  padding: 240px 120px 320px;
`;

export const MainImage = styled.img`
  width: 70%;
  margin: auto;
  display: block;
  margin-bottom: 32px;

  @media only screen and (max-width: 480px) {
    width: 100%;
    margin-bottom: 16px;
  }
`;

export const FourImageGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 8px;
  margin: 32px 0;
  align-items: end;

  img {
    width: 100%;
  }

  span {
    font-size: ${typeScale(-2)};
    text-align: center;
    display: block;
  }

  @media only screen and (max-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }

  @media only screen and (max-width: 480px) {
    grid-template-columns: 1fr;
  }
`;

export const ThreeImageGrid = styled(FourImageGrid)`
  grid-template-columns: 1fr 1fr 1fr;
  @media only screen and (max-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }

  @media only screen and (max-width: 480px) {
    grid-template-columns: 1fr;
  }
`;

export const TwoImageGrid = styled(FourImageGrid)`
  grid-template-columns: 1fr 1fr;

  @media only screen and (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

export const ContactEmail = styled.a`
  overflow: hidden;
  position: relative;
  display: inline-block;
  text-decoration: none;
  color: inherit;
  width: 100%;
  font-size: inherit;
  cursor: pointer;

  ::before,
  ::after {
    content: "";
    position: absolute;
    width: 100%;
    left: 0;
  }

  ::after {
    content: attr(data-replace);
    height: 100%;
    top: 0;
    transform-origin: 100% 50%;
    transform: translate3d(0, 150%, 0);
    transition: transform 0.3s ease-in-out;
    color: ${stripeTheme.grey[60]};
  }

  :hover::after {
    transform: translate3d(0, 0, 0);
  }

  span {
    display: inline-block;
    transition: transform 0.3s ease-in-out;
  }

  :hover span {
    transform: translate3d(0%, -150%, 0);
  }
`;

export const WebsiteLink = styled.a`
  overflow: hidden;
  position: relative;
  display: inline-block;
  text-decoration: none;
  color: inherit;
  width: 100%;

  ::before,
  ::after {
    content: "";
    position: absolute;
    width: 100%;
    left: 0;
  }

  ::after {
    content: attr(data-replace);
    height: 100%;
    top: 0;
    transform-origin: 100% 50%;
    transform: translate3d(0, 150%, 0);
    transition: transform 0.3s ease-in-out;
    color: ${stripeTheme.grey[60]};
  }

  :hover::after {
    transform: translate3d(0, 0, 0);
  }

  span {
    display: inline-block;
    transition: transform 0.3s ease-in-out;
  }

  :hover span {
    transform: translate3d(0%, -150%, 0);
  }
`;
