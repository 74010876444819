import { useScroll } from "framer-motion";
import React, { useRef, useEffect, useState } from "react";
import styled from "styled-components";
import { stripeTheme } from "../../themes";
import { ProductsNavBar } from "./SharedComponents/ProductsNavBar";
import * as styles from "./sharedStyles";
import logo from "../../assets/govtech-logo.webp";
import { NextProductSection } from "./SharedComponents/NextProductSection";
import { TitleSection } from "./SharedComponents/TitleSection";
import mainImage from "../../assets/projects/wins/homepage.webp";
import applicationChoice from "../../assets/projects/wins/application_choice.webp";
import officerAppeal from "../../assets/projects/wins/officer_appeal.webp";
import sponsorLevy from "../../assets/projects/wins/sponsor_levy.webp";
import designProcess from "../../assets/projects/wins/design_process.webp";
import designSynthesis from "../../assets/projects/wins/design_synthesis.webp";
import multipleMediums from "../../assets/projects/wins/multiple_mediums.webp";
import paymentScreen from "../../assets/projects/wins/payment_screen.webp";
import paymentPending from "../../assets/projects/wins/payment_pending.webp";
import { ImageCarousell } from "./SharedComponents/ImageCarousell";

const Background = styled(styles.Background)`
  color: ${stripeTheme.blue[100]};
`;

const ColoredTitle = styled(styles.Title)`
  color: ${stripeTheme.blue[100]};
`;

const imageList1 = [
  {
    img: applicationChoice,
    desc: "Clear conversational language used for helper application types.",
  },
  {
    img: officerAppeal,
    desc: "MOM officer's view of an appeal by an employer. Side bar contains required actions by officer.",
  },
  {
    img: sponsorLevy,
    desc: "Some actions cannot be done by a sponsor. Clear actions are shown in order to complete the task.",
  },
];

const imageList2 = [
  {
    img: designSynthesis,
    desc: "Design synthesis after interviews with users. Each round of synthesis is usually done after each interview.",
  },
  {
    img: designProcess,
    desc: "Design process after synthesis: Develop personas. Identify common user journeys. Create user flow with low fi mockups. Turn mockups into wireframes to test.",
  },
  {
    img: multipleMediums,
    desc: "Working on multiple mediums and touch points of users. The experience extends beyond the online portal to emails and physical letters, which we completely redesigned and implemented from the ground up.",
  },
];

const imageList3 = [
  {
    img: paymentScreen,
    desc: "A simple payment screen with frontend validation. Keeping the same design language with decisions and important info at the right of the screen.",
  },
  {
    img: paymentPending,
    desc: "We introduced status banners so that users can keep track of their latest payments made. Reducing the anxiety and stress of not knowing if their payment had been completed or not.",
  },
];

export const WINS = () => {
  const hiddenRef = useRef<HTMLDivElement>(null);
  const overviewRef = useRef<HTMLElement>(null);
  const approachRef = useRef<HTMLElement>(null);
  const showcaseRef = useRef<HTMLElement>(null);
  const reflectionsRef = useRef<HTMLElement>(null);
  const [refLocation, setRefLocation] = useState(0);
  const [currentStep1, setCurrentStep1] = useState(1);
  const [currentStep2, setCurrentStep2] = useState(1);
  const [currentStep3, setCurrentStep3] = useState(1);

  const { scrollYProgress } = useScroll({
    target: hiddenRef,
    offset: ["end end", "start start"],
  });

  useEffect(() => {
    if (scrollYProgress.get() !== 0) {
      setRefLocation(scrollYProgress.get());
    }
    scrollYProgress.onChange((v) => {
      setRefLocation(v);
    });
  }, [scrollYProgress]);

  return (
    <Background>
      <ProductsNavBar
        refs={{
          hiddenRef: hiddenRef,
          overviewRef: overviewRef,
          approachRef: approachRef,
          showcaseRef: showcaseRef,
          reflectionsRef: reflectionsRef,
        }}
        companyLogo={logo}
        refLocation={refLocation}
      />
      <TitleSection
        themeColor={`radial-gradient(
          at bottom left,
          ${stripeTheme.blue[90]},
          ${stripeTheme.blue[80]},
          ${stripeTheme.cyan[50]}
        )`}
        name="Workpass Integration System"
      />
      <styles.RegularSection ref={overviewRef}>
        <styles.HiddenRefDiv ref={hiddenRef} />
        <styles.MainImage src={mainImage} />
        <styles.SubTitle>
          Digitising and modernising the multi-touchpoint experience
          <br />
          for families managing their helpers’ work permits.
        </styles.SubTitle>
        <styles.DescriptionWrapper>
          <div>
            <styles.Description>
              Singapore families have always had a high reliance on foreign
              domestic workers (FDW) for help in the household. WINS allows FDW
              employers and agencies to interact with the Ministry of Manpower
              (MOM) on all matters related to FDW permits. The previous legacy
              system was outdated and hard to maintain, often falling behind
              operational policy changes. It’s interface was user-hostile and
              did not facilitate interaction between parties well. I was brought
              in together with an agile team of developers, designers and a
              scrum master to migrate it over to a modern, more well oiled
              system. When I joined the team the first MVP of the new system was
              already live and I focused on introducing new features while
              maintaining good coding practices. As a team we had full control
              and autonomy of the product build and design while features were
              defined by the business team.
            </styles.Description>
            <styles.Category>MY ROLE</styles.Category>
            <styles.Description>
              I developed and maintained a live system serving 300,000+ users.
              Building in new features to improve user experience and to keep up
              with operational policies. I led the design and implementation of
              a new payment feature and user flow, facilitating 150,000+
              transactions per year.
            </styles.Description>
            <styles.Category>SOFTWARE STACK</styles.Category>
            <styles.CategoryList>
              Ruby on Rails, Angular 2, Selenium, GoCD, RabbitMQ
            </styles.CategoryList>
          </div>
          <div>
            <styles.Category>SKILLS UTILISED</styles.Category>
            <styles.CategoryList>
              Full stack development
              <br />
              E2E testing development
              <br />
              Database design
              <br />
              Agile Coaching
              <br />
              Stakeholder management
            </styles.CategoryList>
            <styles.Category>COMPANY</styles.Category>
            <styles.CategoryList>
              Government Technology Agency
              <br />
              Ministry of Manpower
            </styles.CategoryList>
            <styles.Category>LIVE PRODUCTS</styles.Category>
            <styles.CategoryList>
              <styles.WebsiteLink
                href="https://www.mom.gov.sg/eservices/services/work-permit-transactions-for-domestic-helpers-and-confinement-nannies"
                target="_blank"
                data-replace="https://www.mom.gov.sg/eservices/services/work-permit-transactions-for-domestic-helpers-and-confinement-nannies"
              >
                <span>WINS public website →</span>
              </styles.WebsiteLink>
              <br />
              (Only usable when logging in with SingPass)
            </styles.CategoryList>
          </div>
        </styles.DescriptionWrapper>
      </styles.RegularSection>
      <ImageCarousell
        images={imageList1}
        currentStep={currentStep1}
        setCurrentStep={setCurrentStep1}
        color={stripeTheme.blue[90]}
      />
      <styles.RegularSection ref={approachRef}>
        <styles.ApproachWrapper>
          <styles.ApproachHeader>
            Evangelising agile in the government sector.
          </styles.ApproachHeader>
          <styles.GenericText>
            In favour of getting stakeholder buy in and encouraging a more user
            centric approach to policy making, we adopted an agile approach to
            delivery while constantly engaging our users. This includes teaching
            developers and business analysts to conduct user interviews and
            designing participatory workshops, letting the business users see
            their user’s problems first hand.
            <br />
            <br />
            We then paired Agile with coding best practices. Pair programming,
            TDD, a strong CI/CD pipeline, rigorous E2E testing, and regular
            sharing sessions all contributed to us building a robust and
            maintainable code base. It is hard to put clean code into words and
            to showcase it here, but I’d like outline our workflow that I took
            with me to other products I worked on. Although it doesn’t suit
            every product team, it stuck with me as a well oiled workflow for
            the team at that time.
            <ul>
              <li>
                All users of the team would sit together within earshot of the
                next and there would be a physical kanban board with prioritised
                stories and a backlog.
              </li>
              <li>
                Before being played, each story would be planned and gone
                through a few iterations with acceptance criteria having inputs
                from BAs, Designers and a feature lead. They would be
                responsible for catching scenarios and possible bugs during
                implementation.
              </li>
              <li>
                Every developer pair would change once in awhile and stories
                would be taken solely by priority to ensure features get out to
                users faster and to facilitate sharing of knowledge.
              </li>
              <li>
                Each story starts off with a kick off where the BA, Designer and
                QA joins the developer pair to discuss and clarify details of
                the story.
              </li>
              <li>
                The pair then starts developing using trunk based development.
                Constantly pushing small and workable commits into the main
                branch, practicing TDD while developing and ensuring unit tests
                all pass. Once the story is completed, we would run a series of
                smoke E2E tests. And finally call in the same BA, Designer and
                QA for a desk check where we would showcase our developed story.
              </li>
              <li>
                If all is good, the QA goes through the acceptance criteria and
                creates the relevant E2E tests to cover them while the
                developers move on to another story. Bugs found in the midst of
                developing are parked aside and given to the PO to prioritise on
                the spot.
              </li>
              <li>
                Every sprint we take the commit ID we want to push into
                production and test it on UAT before pushing the big red button
                to trigger the CD pipeline. Bugs = Smashed. Features = Built.
                Users = Happy
              </li>
            </ul>
          </styles.GenericText>
        </styles.ApproachWrapper>
        <styles.ApproachWrapper>
          <styles.ApproachHeader>
            Inclusivity and accessibility at the forefront.
          </styles.ApproachHeader>
          <styles.GenericText>
            Being a customer facing product at the scale that it is (300,000+
            public users and potentially many more for years to come), Singapore
            citizens from all walks of life would need to be able to access the
            system. All individuals with a diverse range of sight, hearing,
            mobility and cognitive abilities should have access to the same
            features.
            <br />
            <br />
            This guided our principles both when designing and developing our
            features. While designing we would ensure that the color contrasts
            of screens would be within the WCAG 2.0 AA guidelines. We would also
            try to reduce motion, limit popups and unexpected flashes, and avoid
            time limits on actions. We would ensure that the copy of
            instructions be clear and concise. And most importantly, due to
            Singapore’s multi-racial society, we designed UI catered to the 4
            main languages in Singapore, English, Malay, Tamil, and Mandarin
            Chinese.
            <br />
            <br />
            During user testing we would ensure that we get a wide range of
            users to speak to. A user’s input has a huge effect downstream and
            if one does not deliberately ensure inclusivity in the input, the
            output will surely not be.
            <br />
            <br />
            Regarding development, having proper accessibility practices is of
            paramount importance. Developing proper responsive behaviour goes
            without saying, but we also ensure other practices while developing.
            We would use the proper semantics when defining HTML tags, as it
            affects what screen readers would produce. Understanding when to use
            px, em and rem is also important. Disallowing users control over
            their browser font size especially on mobile is nothing short of
            disrespectful to the end user. But also knowing when there is a time
            and place for px for design specific margins or paddings is
            important. Interactive elements would also have proper labels and
            ARIA specifications to enable the proper use of assistive
            technologies.
            <br />
            <br />
            Through all this we would occasionally test out the product with
            users of varying disabilities to ensure it was accessible. It also
            helped a lot that there was a blind developer in our company that we
            would sometimes ask to test our product :).
          </styles.GenericText>
        </styles.ApproachWrapper>
        <styles.ApproachWrapper>
          <styles.ApproachHeader>
            Users first, users second, users third.
          </styles.ApproachHeader>
          <styles.GenericText>
            Throughout each feature and sprint, the team would participate in
            user interviews to address problems brought up by the users. These
            interviews validate and make the impact of the work we do clearer to
            the team. It was through the design process of speaking directly to
            the public citizens of Singapore, coupled with Singapore’s aging
            population, that the team decided to implement a delegation feature
            on the online portal.
            <br />
            <br />
            With this new feature, users would be able to do certain
            transactions on behalf of their family members whom have
            difficulties doing so, like renewing their helper’s work permit.
            This feature brought together multiple stakeholders involved in the
            system beyond just the main users, including MOM’s processing
            officers, their legal team, policy personnel, etc. By using a
            design-centric approach to view systems as a whole, we found
            solutions to support our users given policy constraints while
            keeping operational changes to a minimum. We even tested the offline
            interactions of our user flows like changing the copy and layout of
            physical reminder forms for the elderly. Following implementation we
            gave our feedback directly to the policy decision makers for them to
            review. All this led to over 600 transactions in a first year of
            implementation, and users asking for more sponsor features to be
            integrated.
          </styles.GenericText>
        </styles.ApproachWrapper>
      </styles.RegularSection>
      <ImageCarousell
        images={imageList2}
        currentStep={currentStep2}
        setCurrentStep={setCurrentStep2}
        color={stripeTheme.blue[90]}
      />
      <styles.RegularSection ref={showcaseRef}>
        <ColoredTitle>
          Feature showcase.
          <br />
          Revamping the payments experience
        </ColoredTitle>
        <styles.GenericText>
          As the lead for the payments feature, I was responsible for leading
          the software design, stakeholder engagement, and implementation of
          transitioning our payments gateway from Cybersource to Braintree. I
          also took the opportunity to work closely with our designer to revamp
          and implement a new UI design to facilitate this new transition and
          improve the user experience.
          <br />
          <br />
          The change was incited by the expiry of the old contract and its price
          for renewal. It also caused more than an acceptable number of failed
          transactions.
          <br />
          <br />
          While developing we gated the feature behind a feature flag,
          constantly maintaining contact with the Braintree support for
          integration issues. We had planned out the integrations such as user
          notifications, out of band transactions, and iframe placements for
          bank authentication.
          <br />
          <br />
          One of the major user facing overhauls we did was to improve on the
          user feedback once a payment is made. In the prior implementation,
          users would finish paying and hope that the payment went through, only
          to find out the result later by their bank if the amount bounced back
          or if it was successful. Our research led us to understand the user’s
          frustrations and anxiety when their uncertainty concerned their money.
          In addition to adding a clear and concise success screen, we
          introduced new banner notifications to inform the user of the
          transactions progress while waiting for settlement. We also stored one
          time user interactions with the banners in the local storage for a
          more personalised experience. All this facilitated 150,000+
          transactions yearly for our users, ensuring they had a smooth and
          anxiety free user experience, and anecdotally (unfortunately I did not
          have the numbers) reduced the average number of failed transactions.
        </styles.GenericText>
      </styles.RegularSection>
      <ImageCarousell
        images={imageList3}
        currentStep={currentStep3}
        setCurrentStep={setCurrentStep3}
        color={stripeTheme.blue[90]}
      />
      <styles.RegularSection ref={reflectionsRef}>
        <ColoredTitle>Reflections.</ColoredTitle>
        <styles.GenericText>
          Working on this product was special to me due to not just the scale,
          but mainly how real impact felt to our users. It touches the daily
          lives of Singapore citizens and challenges the common perception that
          government systems are slow and difficult to use.
          <br />
          <br />
          Beyond designing and building for scale and inclusivity, working on
          this product gave me an opportunity to work on solutions outside of
          the digital realm, such as seeing how policy decisions trickle down to
          the users. It allowed me to truly view the system as a whole and
          understand how sometimes the best solution is not necessarily one
          where a new feature is created in a digital product.
        </styles.GenericText>
      </styles.RegularSection>
      <NextProductSection
        name="MyMoneySense"
        themeColor={`radial-gradient(
          at bottom left,
          ${stripeTheme.blue[90]},
          ${stripeTheme.blue[80]},
          ${stripeTheme.cyan[50]}
        )`}
        hoverColor={`radial-gradient(
          at bottom left,
          ${stripeTheme.cyan[90]},
          ${stripeTheme.cyan[80]},
          ${stripeTheme.blue[50]}
        )`}
        route="/mymoneysense"
      />
    </Background>
  );
};
