import { useScroll } from "framer-motion";
import React, { useRef, useEffect, useState } from "react";
import styled from "styled-components";
import { stripeTheme } from "../../themes";
import { ProductsNavBar } from "./SharedComponents/ProductsNavBar";
import * as styles from "./sharedStyles";
import logo from "../../assets/govtech-logo.webp";
import { NextProductSection } from "./SharedComponents/NextProductSection";
import { TitleSection } from "./SharedComponents/TitleSection";
import { ImageCarousell } from "./SharedComponents/ImageCarousell";
import mainImage from "../../assets/projects/SupplyAlly/homepage.webp";
import login from "../../assets/projects/SupplyAlly/login.webp";
import scanId from "../../assets/projects/SupplyAlly/scan_id.webp";
import items from "../../assets/projects/SupplyAlly/items.webp";
import updates from "../../assets/projects/SupplyAlly/updates.webp";
import config from "../../assets/projects/SupplyAlly/config.webp";

const Background = styled(styles.Background)`
  color: ${stripeTheme.yellow[100]};
`;

const imageList1 = [
  {
    img: login,
    desc: "",
  },
  {
    img: scanId,
    desc: "",
  },
  {
    img: items,
    desc: "",
  },
];

const imageList2 = [
  {
    img: config,
    desc: "",
  },
  {
    img: updates,
    desc: "",
  },
];

export const SupplyAlly = () => {
  const hiddenRef = useRef<HTMLDivElement>(null);
  const storyRef = useRef<HTMLElement>(null);
  const howItWorksRef = useRef<HTMLElement>(null);
  const [refLocation, setRefLocation] = useState(0);
  const [currentStep1, setCurrentStep1] = useState(1);
  const [currentStep2, setCurrentStep2] = useState(1);

  const { scrollYProgress } = useScroll({
    target: hiddenRef,
    offset: ["end end", "start start"],
  });

  useEffect(() => {
    if (scrollYProgress.get() !== 0) {
      setRefLocation(scrollYProgress.get());
    }
    scrollYProgress.onChange((v) => {
      setRefLocation(v);
    });
  }, [scrollYProgress]);

  return (
    <Background>
      <ProductsNavBar
        refs={{
          hiddenRef: hiddenRef,
          storyRef: storyRef,
          howItWorksRef: howItWorksRef,
        }}
        companyLogo={logo}
        refLocation={refLocation}
      />
      <TitleSection
        themeColor={`radial-gradient(
          at bottom left,
          ${stripeTheme.yellow[90]},
          ${stripeTheme.yellow[80]},
          ${stripeTheme.orange[50]}
        )`}
        name="SupplyAlly"
      />
      <styles.RegularSection ref={storyRef}>
        <styles.HiddenRefDiv ref={hiddenRef} />
        <styles.MainImage src={mainImage} />
        <styles.SubTitle>
          A flexible lightweight mobile solution
          <br />
          enabling verifiable and auditable dispersions of physical goods.
        </styles.SubTitle>
        <styles.DescriptionWrapper>
          <styles.Description>
            During the early days of COVID-19 there was a need to distribute
            various goods from the government to the citizens of Singapore. The
            need started with masks, and there was an urgent need to get
            something out fast. In 4 days, a bootstrap team was pulled together
            and we made a product built off a NoSQL and serverless solution
            ensuring that we would be able to pivot the solution as required. At
            that point of time we did not know the scale we were working with or
            how users were defined. As such we had to develop a form of
            authorisation and authentication that was scalable with the size of
            the current and future use cases.
            <br />
            <br />
            The solution was to be used while discussions for using vending
            machines to disburse masks were still in progress. Knowing that
            there would be a need to expand this solution to other use cases,
            and that each of the use cases would be relatively short lived, we
            built the product to be as modular as possible, having individual
            use cases run on their own siloed instance of the product. This
            ensured ownership and the ease of debugging within each use case.
            The serverless architecture also allowed us to consume less
            resources as specific use cases were ending.
            <br />
            <br />
            What started with 1 mask distribution exercise grew to 2, then 3,
            and soon, mask distributions and general care packages for COVID-19
            were a norm. The product grew not just in depth in a single use
            case, but also expanded to other use cases such as food
            distribution, and even home schooling laptops for the lesser
            fortunate families. I personally led the initiative for the latter,
            with the use case coming from a non-profit organisation, Engineering
            Good. From liaising with their employees to extending the product to
            their use case, ensuring that each of their beneficiaries had a
            laptop for home schooling.
            <br />
            <br />
            <styles.WebsiteLink
              href="https://www.supplyally.gov.sg/"
              target="_blank"
              data-replace="FAQ website"
            >
              <span>Learn more about SupplyAlly →</span>
            </styles.WebsiteLink>
          </styles.Description>
        </styles.DescriptionWrapper>
      </styles.RegularSection>
      <ImageCarousell
        images={imageList1}
        currentStep={currentStep1}
        setCurrentStep={setCurrentStep1}
        color={stripeTheme.yellow[90]}
      />
      <styles.RegularSection ref={howItWorksRef}>
        <styles.ApproachWrapper>
          <styles.ApproachHeader>How it works.</styles.ApproachHeader>
          <styles.GenericText>
            The distributors of the items are the users of the app and each use
            case is given a predetermined number of accounts as required by
            them. These accounts are unique to the individual and had passwords
            linked to QR codes we would send to them. Each QR code would
            correspond to its respective use case, allowing users to work within
            their own use case agnostic of others.
            <br />
            <br />
            Before hand, the organisations that own the use case will work with
            the team to define lists of items to be distributed and how often
            they refresh. E.g. allowing individuals to collect 1 bag of rice per
            week. They will also define how to identify legitimate beneficiaries
            and that would be built into that instance of app for that use case.
            <br />
            <br />
            Upon logging in, users will scan or enter an individual’s NRIC
            (Singapore’s ID number). On the backend, it will run a series of
            checks such as if the individual is eligible or if they have
            collected any items before. Users will then have the ability to
            choose the items to distribute to the user based on the predefined
            list. They then confirm that they are distributing the list of items
            and it logs that into the database.
          </styles.GenericText>
        </styles.ApproachWrapper>
      </styles.RegularSection>
      <ImageCarousell
        images={imageList2}
        currentStep={currentStep2}
        setCurrentStep={setCurrentStep2}
        color={stripeTheme.yellow[90]}
      />

      <NextProductSection
        name="T-Gini"
        themeColor={`radial-gradient(
          at bottom left,
          ${stripeTheme.yellow[90]},
          ${stripeTheme.yellow[80]},
          ${stripeTheme.orange[50]}
        )`}
        hoverColor={`radial-gradient(
          at bottom left,
          ${stripeTheme.orange[90]},
          ${stripeTheme.orange[80]},
          ${stripeTheme.yellow[50]}
        )`}
        route="/tgini"
      />
    </Background>
  );
};
