import React, { useRef, useEffect, useState } from "react";
import styled from "styled-components";
import { stripeTheme, typeScale } from "../../themes";
import * as styles from "./sharedStyles";
import logo from "../../assets/govtech-logo.webp";
import { ProductsNavBar } from "./SharedComponents/ProductsNavBar";
import { useScroll } from "framer-motion";
import { NextProductSection } from "./SharedComponents/NextProductSection";
import { TitleSection } from "./SharedComponents/TitleSection";
import { ImageCarousell } from "./SharedComponents/ImageCarousell";
import mainImage from "../../assets/projects/mymoneysense/what_can_you_do.webp";
import dashboard from "../../assets/projects/mymoneysense/dashboard.webp";
import savingTargets from "../../assets/projects/mymoneysense/saving_targets.webp";
import buyingFirstHome from "../../assets/projects/mymoneysense/buying_first_home.webp";
import archetypes from "../../assets/projects/mymoneysense/archetypes.webp";
import ideation from "../../assets/projects/mymoneysense/ideation.webp";
import userflowFeedback from "../../assets/projects/mymoneysense/userflow_feedback.webp";
import mvpEstimation from "../../assets/projects/mymoneysense/mvp_estimation_plan.webp";
import chatbotFlow from "../../assets/projects/mymoneysense/chatbot_flow.webp";
import chatbotStart from "../../assets/projects/mymoneysense/chatbot_start.webp";
import chatbotInflow from "../../assets/projects/mymoneysense/chatbot_inflow.webp";
import chatbotEmergency from "../../assets/projects/mymoneysense/chatbot_emergency.webp";
import testingManualReco from "../../assets/projects/mymoneysense/testing_manual_recommendations.webp";
import dashboardConcept from "../../assets/projects/mymoneysense/dashboard_concept.webp";
import lowfiScreens from "../../assets/projects/mymoneysense/lowfi_screens.webp";
import mapYourGoalsGif from "../../assets/projects/mymoneysense/map_your_goals_plan.gif";
import mapYourGoals from "../../assets/projects/mymoneysense/map_your_goals.webp";
import initialWireframes from "../../assets/projects/mymoneysense/initial_wireframes.webp";
import hifiWireframes from "../../assets/projects/mymoneysense/hifi_wireframes.webp";
import iterativeDesign from "../../assets/projects/mymoneysense/iterative_design.webp";
import sgfindex from "../../assets/projects/mymoneysense/sgfindex.webp";
import newService from "../../assets/projects/mymoneysense/new_service.webp";
import journeys from "../../assets/projects/mymoneysense/journeys.webp";

const Background = styled(styles.Background)`
  color: ${stripeTheme.cyan[100]};
`;

const ColoredTitle = styled(styles.Title)`
  color: ${stripeTheme.cyan[100]};
`;

const ColoredSection = styled(styles.ColoredSection)`
  background: ${stripeTheme.cyan[90]};
`;

const StyledSpan = styled.span`
  font-size: ${typeScale(-2)};
  text-align: center;
  display: block;
`;

const imageList1 = [
  {
    img: dashboard,
    desc: "Dashboard of your personal financial health, with visualisations and recommendations.",
  },
  {
    img: savingTargets,
    desc: "A breakdown of your saving targets and progress towards big ticket items.",
  },
  {
    img: buyingFirstHome,
    desc: "A guide for young couples on setting aside capital while planning to buying their first home.",
  },
];

const imageList2 = [
  {
    img: archetypes,
    desc: "Card sorting activity with users to understand their prefered tone of voice for the product.",
  },
  {
    img: ideation,
    desc: "Going through a bunch of ideas with the team from a crazy 8s exercise.",
  },
  {
    img: userflowFeedback,
    desc: "Having the internal team to try out and comment on a low fi user flow of the app.",
  },
  {
    img: mvpEstimation,
    desc: "Exercise of effort and value estimation for MVP feature prioritisation.",
  },
];

const imageList3 = [
  {
    img: sgfindex,
    desc: "Connecting to SGFinDex to connect real time updated data from your bank account, credit card, insurance, CPF, and investments.",
  },
  {
    img: newService,
    desc: "Some screens of the final system after launch, showcasing a breakdown of cashflow and financial concepts.",
  },
  {
    img: journeys,
    desc: "Quick and guided experiences to bring users through more complex and timely events in their lives.",
  },
];

export const MyMoneySense = () => {
  const hiddenRef = useRef<HTMLDivElement>(null);
  const overviewRef = useRef<HTMLElement>(null);
  const approachRef = useRef<HTMLElement>(null);
  const showcaseRef = useRef<HTMLElement>(null);
  const reflectionsRef = useRef<HTMLElement>(null);
  const [refLocation, setRefLocation] = useState(0);
  const [currentStep1, setCurrentStep1] = useState(1);
  const [currentStep2, setCurrentStep2] = useState(1);
  const [currentStep3, setCurrentStep3] = useState(1);

  const { scrollYProgress } = useScroll({
    target: hiddenRef,
    offset: ["end end", "start start"],
  });

  useEffect(() => {
    if (scrollYProgress.get() !== 0) {
      setRefLocation(scrollYProgress.get());
    }
    scrollYProgress.onChange((v) => {
      setRefLocation(v);
    });
  }, [scrollYProgress]);

  return (
    <Background>
      <ProductsNavBar
        refs={{
          hiddenRef: hiddenRef,
          overviewRef: overviewRef,
          approachRef: approachRef,
          showcaseRef: showcaseRef,
          reflectionsRef: reflectionsRef,
        }}
        companyLogo={logo}
        refLocation={refLocation}
      />
      <TitleSection
        themeColor={`radial-gradient(
          at bottom left,
          ${stripeTheme.cyan[90]},
          ${stripeTheme.cyan[80]},
          ${stripeTheme.green[50]}
        )`}
        name="MyMoneySense"
      />
      <styles.RegularSection ref={overviewRef}>
        <styles.HiddenRefDiv ref={hiddenRef} />
        <styles.MainImage src={mainImage} />
        <styles.SubTitle>
          Making financial planning accessible for everyone
          <br />
          by visualising the interplay between spending and saving.
        </styles.SubTitle>
        <styles.DescriptionWrapper>
          <div>
            <styles.Description>
              Despite Singapore’s relatively high GDP per capita, the
              Singaporeans were not managing their finances well. Based on the
              Financial Literacy Survey, conducted by the Singapore government,
              most Singaporeans had misconceptions about financial planning
              despite a good understanding of basic financial concepts. More
              than 50% of Singaporeans were not insured well enough. Half of
              young working adults have not started thinking about financial
              planning. And 2/3 Singaporeans did not have enough savings to last
              them more than 6 months, meaning they were a single retrenchment
              away from getting into debt.
            </styles.Description>
            <styles.Category>MY ROLE</styles.Category>
            <styles.Description>
              Being one of the 2 software engineers in the founding team of 4, I
              led the planning and implementation of the agile and iterative
              approach we would take to develop an innovative and complex user
              centric product. This includes software and architecture decisions
              like languages and frameworks used, solution architecture design,
              software practices, etc.
              <br />
              <br />I was also heavily involved in the product conceptualisation
              and strategy, regularly planning and conducting user research,
              workshops, and showcases to our stakeholders.
            </styles.Description>
            <styles.Category>SOFTWARE STACK</styles.Category>
            <styles.CategoryList>
              React, NodeJS, Travis, MySQL, TestCafe, EKS
            </styles.CategoryList>
          </div>
          <div>
            <styles.Category>SKILLS UTILISED</styles.Category>
            <styles.CategoryList>
              Full stack development
              <br />
              Database design
              <br />
              Architechture design
              <br />
              CI/ CD setup and development
              <br />
              Agile Coaching
              <br />
              Stakeholder management
              <br />
              User research
              <br />
              UI and interaction design
            </styles.CategoryList>
            <styles.Category>COMPANY</styles.Category>
            <styles.CategoryList>
              Government Technology Agency
              <br />
              Ministry of Manpower
            </styles.CategoryList>
            <styles.Category>LIVE PRODUCTS</styles.Category>
            <styles.CategoryList>
              <styles.WebsiteLink
                href="https://www.mymoneysense.gov.sg/"
                target="_blank"
                data-replace="https://www.mymoneysense.gov.sg/"
              >
                <span>MyMoneySense site →</span>
              </styles.WebsiteLink>
              <br />
              (Only usable when logging in with SingPass)
              <br />
              <br />
              <styles.WebsiteLink
                href="https://fpds-myg.netlify.app/goals"
                data-replace="https://fpds-myg.netlify.app/goals"
                target="_blank"
              >
                <span>MapYourGoals Prototype →</span>
              </styles.WebsiteLink>
              <br />
              Protoype made to test assumptions
            </styles.CategoryList>
          </div>
        </styles.DescriptionWrapper>
      </styles.RegularSection>
      <ImageCarousell
        images={imageList1}
        currentStep={currentStep1}
        setCurrentStep={setCurrentStep1}
        color={stripeTheme.cyan[90]}
      />
      <styles.RegularSection ref={approachRef}>
        <styles.ApproachWrapper>
          <styles.ApproachHeader>
            Align Discovery and Principles.
          </styles.ApproachHeader>
          <styles.GenericText>
            We adopted a very heavy user research process for discovery,
            aligning business objectives to problem driven statistics from our
            users. During the first phase we nailed down the age group we were
            solving the problem for. Young working adults were chosen for their
            willingness to learn, familiarity with digital products, and time
            horizon left to retirement. It was also a very apt time to introduce
            financial planning into their lives as they enter a new phase of
            life.
            <br />
            <br />
            We conducted 30+ user interviews across a wide range of people. From
            independent financial advisors, to fresh graduates. From higher
            income individuals, to those from less well to do backgrounds. We
            included our stakeholders in some of these interviews and also
            conducted various workshops to align on the research and to bring
            them closer to the users. This led us to create our persona we were
            going to target for our first MVP.
            <br />
            <br />
            Through the user interviews we produced 4 key insights to the
            financial planning process, and 6 design principles to address the
            concerns of our persona.
            <br />
            <br />
            <b>Key insights:</b>
            <ul>
              <li>
                Awareness of financial planning is most often triggered by
                tangible life events, or incidents faced by family members (e.g.
                health conditions).
              </li>
              <li>
                It is most common to plan for short term goals like travel, as
                it is specific and easier to define. Longer-term goals with
                greater uncertainty add another layer of complexity and barrier
                to planning.
              </li>
              <li>
                Job emergencies are not salient, compared to health, especially
                if they just started working.
              </li>
              <li>
                The planning step is most often unsupported and neglected due to
                a lack of knowledge or urgency.
              </li>
            </ul>
            <b>Design principles:</b>
            <ul>
              <li>
                Provide timely, contextual support: Provide users with timely,
                supportive feedback that what they're doing is working
              </li>
              <li>Actionable: Make it easy to act</li>
              <li>
                Mirror: support self-reflection, show the user all of their
                responses assembled in the same place, allow them to make
                choices by acting as a mirror and reflecting the necessary
                information.
              </li>
              <li>Interpreter- make it easy to understand</li>
              <li>Be professional yet approachable</li>
              <li>Give users control</li>
            </ul>
            With these guidelines in place, we set out to start experimenting
            with various means on how we can provide the best value to our
            users. We constantly referred back to our guidelines whenever we hit
            an uncertainty and this kept us grounded as time went on.
          </styles.GenericText>
        </styles.ApproachWrapper>
        <styles.ApproachWrapper>
          <styles.ApproachHeader>
            Learn fast and navigate uncertainty with quick experiments and
            prototyping.
          </styles.ApproachHeader>
          <styles.GenericText>
            Our business team had no financial domain expert in the team at the
            start. Thus to overcome heavy uncertainty, we experimented with
            multiple prototypes to test out our hypothesis and learning
            objectives. This helped not just build contextual knowledge through
            the team, but also gave the team and stakeholders much needed
            quantitative feedback for our decisions.
            <br />
            <br />
            We would make limited releases of live prototypes to the public over
            time. Ensuring that each prototype was isolated and did not store
            any user data on a database or contain any confidential information.
            With this we were able to bypass the typical checks required for
            products, so as to learn fast and build fast. We had also
            implemented a feedback system throughout the prototypes so that we
            could gather ideas and keep in contact with users who were
            particularly interested in shaping the product.
          </styles.GenericText>
        </styles.ApproachWrapper>
        <styles.ApproachWrapper>
          <styles.ApproachHeader>
            Finding and utilising a government’s unique position.
          </styles.ApproachHeader>
          <styles.GenericText>
            At the start through market research and multiple interviews with
            large companies like DBS to smaller startups like MoneyOwl and
            BetterTradeOff (rebranded to Up for the confusions of acronyms in
            Singapore), we found a general lay of the land to understand where
            we would fit into the seemingly saturated space of digital financial
            planning.
            <br />
            <br />
            One would not typically trust one’s personal financial decisions
            with the government. Well that was what we initially thought.
            Through public surveys and the 30+ user interviews we conducted, we
            realised the public’s high level of trust in the government
            especially among the demographics of young adults.
            <br />
            <br />
            With the financial scene littered with insurance agents from banks
            coupled closely to their financial products, to sponsored blogs
            giving advice on how to get coverage, to acquaintances turned
            financial planners pushing their agenda, our users were lacking an
            unbiased perspective in their personal financial journey. So we were
            in prime position to provide that free and trustworthy advice.
            <br />
            <br />
            The fine line we had to walk was to not provide insights and
            recommendations that seemed to paternalistic and forced. This was
            expanded on when users chose “Mirror” and “Interpreter” when we held
            an exercise with our users letting them choose their preferred tone
            of voice for the product.
            <br />
            <br />
            There was also an intriguing perspective regarding personal data
            that the government already stored information like personal credit
            card data and financial statements, and users were more focused on
            us keeping the data secure rather than the us actually acquiring the
            data. This led us to gain more confidence and eventually to push for
            the creation of SGFinDex, where I played an advisory role and
            representative of the first use case of a platform for banks to
            share their customer’s bank account details with each other. (More
            on this below in Feature Showcase section)
          </styles.GenericText>
        </styles.ApproachWrapper>
        <styles.ApproachWrapper>
          <styles.ApproachHeader>
            Lowering the feedback loop as much as possible.
          </styles.ApproachHeader>
          <styles.GenericText>
            In order to increase our learnings, there was an indicative
            understanding that the speed of decision making was something to
            optimise for. Where it would be better to make twice the decisions
            at half the precision. And the way to achieve that would be to
            design as short of a feedback loop as possible.
            <br />
            <br />
            The business team would sit next to us to encourage frequent check
            ins on the product’s behaviours. We would do ad hoc hallway testings
            or A/B testings within the team for screen designs. We would often
            write our algorithms on a whiteboard for other developers to provide
            their thoughts over time. All through this we would have frequent
            retrospectives in containers (i.e groups of people) to improve our
            processes to the current situation.
            <br />
            <br />
            As we had an overall goal of changing user behaviour, we had a big
            emphasis on implementing highly interactive solutions. This required
            the need for comprehensive user testing on an interactive prototype.
            Thus developers and designers both conducted user testings together,
            making real time changes to the prototype as feedback was given. The
            users would point out what they would like to change, and we would
            edit the code to reflect it to show them again. This collaborative
            effort allowed us to adapt to what the users were asking for and
            provided clarity on their needs.
          </styles.GenericText>
        </styles.ApproachWrapper>
      </styles.RegularSection>
      <ImageCarousell
        images={imageList2}
        currentStep={currentStep2}
        setCurrentStep={setCurrentStep2}
        color={stripeTheme.cyan[90]}
      />
      <styles.RegularSection ref={showcaseRef}>
        <ColoredTitle>
          Feature showcase.
          <br />
          Evolution of product through interactive prototypes.
        </ColoredTitle>
        <styles.GenericText>
          Through our first few sprints we had realised that we did not have
          enough substantial data to confidently make decisions on both a UI and
          business level. We would make a few assumptions and hypothesis only to
          realise user interviews and surveys only alleviated the surface level
          questions. We needed a way to validate our product decisions into
          behaviour changes for users. And a way we did that was to release
          interactive prototypes to learn actual user behaviour.
          <br />
          <br />
          The first prototype we released was a chatbot. As we were looking at
          gathering data more at scale, a digital prototype was chosen over a
          physical meet up and interview, this allowed us to get more
          unadulterated responses from users and test out how they would react
          to the first iteration of business rules that we put out. In a single
          2 week sprint we gathered hypothesis, drafted the business rules and
          developed the chatbot to release to the public. We would not only use
          the learnings to refine our business rules, but it also allowed us to
          get an initial sensing of the tone for the product user’s preferred.
          <styles.FourImageGrid>
            <div>
              <img src={chatbotStart} alt="chatbot_start" />
              <span>
                Starting the conversation with the interactive chatbot Margaret.
                Data is saved on local browser across sessions.
              </span>
            </div>
            <div>
              <img src={chatbotInflow} alt="chatbot_inflow" />
              <span>
                Infographic shown to user to test out simple health checks on
                income flow and debt repayment.
              </span>
            </div>
            <div>
              <img src={chatbotEmergency} alt="chatbot_emergency" />
              <span>
                Infographic used to test out tone and business rules on
                emergency fund preparedness.
              </span>
            </div>
            <div>
              <img src={chatbotFlow} alt="chatbot_flow" />
              <span>
                Planning out the chatbot user flow as we were developing.
                Business users could easily edit their flow on the tool.
              </span>
            </div>
          </styles.FourImageGrid>
          <br />
          <br />
          Following the chat bot, we wanted more targetted and in depth
          learnings from our users that we could test out feature concepts and
          the overall user journey. During the first few user interviews at this
          stage, we had found out we were getting quite generic answers, with
          the users struggling to come up with targetted feedback. We realised
          that the screens presented and the scenarios we prepared were not
          reflective enough to their current situation in life, and thus they
          could not relate fully to what was presented to them.
          <br />
          <br />
          We changed our tactic to first request for interviewees to share with
          us their financial situation however much they were willing to. They
          would provide us with info from their financial goals to the debt they
          have to repay down to the numbers. They also shared with us how much
          they have already planned and considered and some even gave credit
          card statements. With this we were able to cater our mockups to each
          individual. Seeing the actual goals they were working towards and a
          breakdown of their actual spending made the interviews a great deal
          more contextual, allowing us to siphon very real and detailed
          reactions and feedback from our interviewees. We had multiple
          interviewees mentioning that it felt very much like an actual
          financial advisor giving tips and recommendations.
          <styles.ThreeImageGrid>
            <div>
              <img src={dashboardConcept} alt="dashboard_concept" />
              <span>
                Relatively early concept of a high fi prototype to test out
                dashboarding with components tested in the chatbot
              </span>
            </div>
            <div>
              <img src={testingManualReco} alt="manual_recommendation" />
              <span>
                Testing the concept of a digital personal finance recommender
                using a "wizard of oz" method with manual calculations and paper
                prototypes.
              </span>
            </div>
            <div>
              <img src={lowfiScreens} alt="dashboard_concept" />
              <span>
                Paper protoypes we used to test concepts and understand users'
                perspective in goal based financial planning.
              </span>
            </div>
          </styles.ThreeImageGrid>
          <br />
          <br />
          Building on our findings from our previous learnings we went on to
          start with our next product experiment. We had found out financial
          planning was most contextual when there was a tangible life event or
          when they had an upcoming goal in mind such as a wedding, vacation, or
          a family member’s health scare. There was also gap in the market for
          goal planning and recommendations and so we built our next prototype
          around that. We made the users define their own life and financial
          goals’ costs and timeline, providing them ample flexibility to take
          charge of their own finances. Following which we built a highly
          interactive chart for them to visualise their finances. With the
          ability to drag and shift goals on the chart, users could see the
          relationship and gap between what they have and what they want to
          achieve financially. We also focused on providing simple
          recommendations for users to help achieve their goals. These
          recommendations were adapted from the business rules and our learnings
          from our chatbot prototype. We would then facilitate committing to a
          savings plan as the call to action for the prototype, as we learnt of
          the importance of keeping them accountable through email reminders.
          <br />
          <br />
          On the development side of things, the highly interactive chart was
          built using d3.js which gave us the flexibility to develop for users
          first. To overcome security requirements for releasing a government
          product, we opted for foregoing a database to store user data, and
          instead kept it on their browser storage encrypted with a user defined
          password, so that goals and finances could be kept between sessions.
          This sped up our go to market substantially, allowing us to keep our
          users engaged and to continue to get feedback.
          <br />
          <br />
          Through time we had noticed a trend that our users tended to not use
          the interactive graph. Although it was flexible and powerful, it was
          daunting and users were not used to the complexities built into the
          interactions, not to mention clunky to use on mobile. The
          recommendations provided were more useful and actionable with users
          more comfortable with changing values down to the specific numbers
          rather than shifting the bars in set steps. These findings helped us
          solidify our final more straightforward and focused design.
          <styles.TwoImageGrid>
            <div>
              <img src={mapYourGoals} alt="map_your_goals" />
              <span>
                A calculator that helps you understand how much you should save
                every month to reach your financial goals. This protoype was
                release to the public to test as initial sensing and to get
                early adopters.
              </span>
            </div>
            <div>
              <img src={mapYourGoalsGif} alt="map_your_goals_gif" />
              <span>
                A highly interactive graphical visualisation of saving towards
                financial goals. Recommendations are auto populated to help you
                achieve your goals in the most effecient way.
              </span>
            </div>
          </styles.TwoImageGrid>
          <br />
          <br />
          We learnt from previous prototypes that specific goals were more
          tangible than others, such as getting your first home, and your
          wedding. Thus we decided to take a modular approach to the product.
          With each module allowing us to dive deeper into the needs of our
          users (still young working adults), with the ability to expand to
          future modules. Working one module at a time also allowed us to
          provide insights and aid our users in a timely manner, creating new
          modules as they would grow older and as their phase of life changes.
          The first module was to be based around the fundamentals of sorting
          out finances and getting an emergency fund. Learning from our previous
          prototype we had chosen a simpler single action scrollable component
          over the complicated interactive bar chart, and focused
          recommendations on achieving their goals and an emergency fund. This
          was while keeping in mind that the next module would be based around
          buying their first home, something extremely close to Singaporeans
          hearts.
          <br />
          <br />
          Even through this phase we developed multiple iterations and tested
          each one rigorously. Each iteration bringing more clarity and focus to
          the data deemed important by our users.
          <styles.TwoImageGrid>
            <div>
              <img src={initialWireframes} alt="initial_wireframes" />
              <span>
                Low-fi initial designs of the app focusing on personal financial
                planning based off goal setting.
              </span>
            </div>
            <div>
              <img src={hifiWireframes} alt="hifi_wireframes" />
              <span>
                High-fi designs of the app after polish making the steps and
                recommendations clearer.
              </span>
            </div>
          </styles.TwoImageGrid>
          <styles.MainImage src={iterativeDesign} alt="iterative_design" />
          <StyledSpan>
            Iterative design and development, each iteration being informed by a
            round of user testing with a working app.
          </StyledSpan>
          <br />
          <br />
          It was also at this juncture that we started experimenting with a way
          aid users to track their finances. We started with allowing them to
          manually key in their purchases grouped into the most common
          categories. This was akin to the more financially savvy individuals we
          interviewed where they would track their expenses on an excel sheet.
          This however obviously wasn’t the best solution with users indicating
          their want to automate this process. So enter the Singapore Financial
          Data Exchange.
          <br />
          <br />
        </styles.GenericText>
        <ColoredTitle>
          On a side note.
          <br />
          SIngapore Financial Data Exchange (SGFinDex).
        </ColoredTitle>
        <styles.GenericText>
          The Singapore Financial Data Exchange was initially conceptualised
          when we started having a use case for helping our users with tracking
          all of their finances. Individual banks had their own analytics and
          implemented their own forms of tracking, but nothing tied them
          together. This meant users with multiple bank accounts and credit
          cards had no way of painting a full picture of their expenses easily.
          With our use case solidifying, we brought together the banks to build
          an API market place for banks to share customer data with each other
          with their consent. I was one of GovTech’s representatives together
          with our SingPass colleagues who oversaw the technical integration
          with the Association of Banks in Singapore, 7 participating banks, SGX
          Central Depository (CDP) and the Monetary Authority of Singapore (MAS)
          to ensure the planning and build of a fair and secure platform.
          <br />
          <br />
          Through many working sessions and months, SGFinDex launched and over
          time it allowed individuals to use their Singpass (our National
          Digital Identity) to retrieve their personal financial information,
          such as deposits, credit cards, loans, and investments, from the
          participating banks and CDP, and their financial information, such as
          HDB loans and CPF balances, from the relevant government agencies.
          <br />
          <br />
          This enabled not just MyMoneySense to start using the data to aid our
          users, but the participating banks all started to integrate it with
          their products and apps. The formation of this ecosystem was nothing
          short of a huge benefit for close to every Singaporean citizen. Now
          almost every bank utilises the system, and more organisations such as
          investment platforms and insurance systems.
          <br />
          <br />
          More info can be found on the
          <styles.WebsiteLink
            href="https://www.mas.gov.sg/development/fintech/sgfindex"
            target="_blank"
            data-replace="https://www.mas.gov.sg/development/fintech/sgfindex"
          >
            <span>MAS website for SGFinDex</span>
          </styles.WebsiteLink>
        </styles.GenericText>
      </styles.RegularSection>
      <ColoredSection>
        <ImageCarousell
          images={imageList3}
          currentStep={currentStep3}
          setCurrentStep={setCurrentStep3}
          color={stripeTheme.cyan[90]}
        />
      </ColoredSection>
      <styles.RegularSection ref={reflectionsRef}>
        <ColoredTitle>Reflections.</ColoredTitle>
        <styles.GenericText>
          My experience on this product from conceptualisation to release has
          been nothing short of enlightening. Not only was there space for me
          and my team to experiment and take ownership of our decisions, but
          individually it allowed me to test and try out a multitude of
          processes and working styles with the team. I gained experience in
          developing a product for scale and inclusivity while making deliberate
          technical decisions taking into account priorities of the team. I have
          grown in making better product decisions, guiding and aligning the
          team in our positioning and goals. And I also learnt how to manoeuvre,
          align, and get buy in between the large number of stakeholders in this
          space, ensuring that the team stayed focus on the user and delivering
          their best.
          <br />
          <br />I believe both MyMoneySense and SGFinDex has so much more to
          offer than what I have built the base of. And I am excited to see how
          they will evolve to provide Singaporeans the clarity and help they
          need to manoeuvre through the complex space of personal finance.
        </styles.GenericText>
      </styles.RegularSection>
      <NextProductSection
        name="SupplyAlly"
        themeColor={`radial-gradient(
          at bottom left,
          ${stripeTheme.cyan[90]},
          ${stripeTheme.cyan[80]},
          ${stripeTheme.green[50]}
        )`}
        hoverColor={`radial-gradient(
          at bottom left,
          ${stripeTheme.green[90]},
          ${stripeTheme.green[80]},
          ${stripeTheme.cyan[50]}
        )`}
        route="/supplyally"
      />
    </Background>
  );
};
