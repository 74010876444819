import { createContext, ReactNode, useContext, useState } from "react";

type OnboardingContextType = {
  onboardingStage: number;
  setOnboardingStage: (stage: number) => void;
};

const OnboardingContext = createContext<OnboardingContextType>(
  {} as OnboardingContextType
);

export const OnboardingProvider = (props: { children: ReactNode }) => {
  const { children } = props;
  const [onboardingStage, setOnboardingStage] = useState(1);

  return (
    <OnboardingContext.Provider
      value={{
        onboardingStage,
        setOnboardingStage,
      }}
    >
      {children}
    </OnboardingContext.Provider>
  );
};

export const useOnboardingContext = () => {
  return useContext(OnboardingContext);
};
