import React, { useRef, useEffect, useState } from "react";
import styled from "styled-components";
import { stripeTheme } from "../../themes";
import * as styles from "./sharedStyles";
import logo from "../../assets/temasek-logo.webp";
import { ProductsNavBar } from "./SharedComponents/ProductsNavBar";
import { useScroll } from "framer-motion";
import { TitleSection } from "./SharedComponents/TitleSection";
import mainImage from "../../assets/projects/winnow/homepage.webp";
import { ImageCarousell } from "./SharedComponents/ImageCarousell";
import dashboard from "../../assets/projects/winnow/dashboard_example.webp";
import peers from "../../assets/projects/winnow/company_peers.webp";
import widgets from "../../assets/projects/winnow/modular_widgets.webp";
import smartSearch from "../../assets/projects/winnow/smart_search.webp";
import watchlist from "../../assets/projects/winnow/watchlist.webp";
import { NextProductSection } from "./SharedComponents/NextProductSection";

const Background = styled(styles.Background)`
  color: ${stripeTheme.red[100]};
`;

const imageList1 = [
  {
    img: dashboard,
    desc: "A dashboard of company widgets based on data across 20+ sources into more than 30 widgets.",
  },
  {
    img: peers,
    desc: "A list of company peers by AI/ML tagging based on the company description, industries, financials and more.",
  },
  {
    img: smartSearch,
    desc: "Allows searching and filtering through various industries to discover new companies for deal origination.",
  },
  {
    img: watchlist,
    desc: "A personalized list of companies aiding each user in their portfolio monitoring.",
  },
  {
    img: widgets,
    desc: "The basis of Winnow, a group of widgets that can be expanded to fit columns giving various granularity of data. And allowing users to customize their widget locations and size to their liking.",
  },
];

export const Winnow = () => {
  const hiddenRef = useRef<HTMLDivElement>(null);
  const overviewRef = useRef<HTMLElement>(null);
  const [refLocation, setRefLocation] = useState(0);
  const [currentStep1, setCurrentStep1] = useState(1);

  const { scrollYProgress } = useScroll({
    target: hiddenRef,
    offset: ["end end", "start start"],
  });

  useEffect(() => {
    if (scrollYProgress.get() !== 0) {
      setRefLocation(scrollYProgress.get());
    }
    scrollYProgress.onChange((v) => {
      setRefLocation(v);
    });
  }, [scrollYProgress]);

  return (
    <Background>
      <ProductsNavBar
        refs={{
          hiddenRef: hiddenRef,
          overviewRef: overviewRef,
        }}
        companyLogo={logo}
        refLocation={refLocation}
        logoSize="small"
      />
      <TitleSection
        themeColor={`radial-gradient(
          at bottom left,
          ${stripeTheme.red[90]},
          ${stripeTheme.red[80]},
          ${stripeTheme.purple[50]}
        )`}
        name="Winnow"
      />
      <styles.RegularSection ref={overviewRef}>
        <styles.HiddenRefDiv ref={hiddenRef} />
        <styles.MainImage src={mainImage} />
        <styles.SubTitle>
          Improving due diligence and portfolio monitoring
          <br />
          by amalgamating data sources and surfacing new findings
        </styles.SubTitle>
        <styles.DescriptionWrapper>
          <div>
            <styles.Description>
              Our different investment teams utilise many sources of data to aid
              in their research on companies, from internal and external sources
              to free and paid data providers. Too much time is spent
              individually piecing data together to form a holistic picture of a
              company, and even when they do so, they often cannot compare the
              data with other peers. How might we link company data together
              from multiple sources to provide investment teams with relevant
              analytics for their deal research?
            </styles.Description>
            <styles.Category>MY ROLE</styles.Category>
            <styles.Description>
              At its largest, including off shore vendors the product team
              consisted of 2 product managers 6 software engineers, and 4 data/
              machine learning engineers. I was a technical product manager,
              leading the team through product conceptualisation and strategy to
              development of the product for 1 of 2 investment groups. I was
              heavily involved with day to day development and UI/UX decisions
              as well as software and architecture design decisions on top of
              usual product manager responsibilities such as stakeholder
              management, roadmap and prioritisation, and the team’s work and
              collaboration processes.
            </styles.Description>
            <styles.Category>SOFTWARE STACK</styles.Category>
            <styles.CategoryList>
              React, NodeJS, Golang, Jenkins, Postgres, Dagster, Python
            </styles.CategoryList>
            <styles.Category>IN A NUTSHELL</styles.Category>
            <styles.Description>
              We gave the users a simple search bar and utilised machine
              learning to link company names and identifiers from multiple
              sources, and gave users a view of analytics of the data from these
              sources in a single pane of glass. We started with pulling
              individual data sources and showcasing them on a widget. Cross
              source analytics was made once sufficient and relevant data
              sources were available. This also led to much requested features
              such as company competitor recommendations and analysis, as well
              as abilities to compare multiple companies side by side.
              <br />
              <br />
              Besides widgets providing analytics on searched companies, we
              developed quality of life and personalised features such as
              company tagging, company watchlists, the ability to change and
              save personal widget layouts, and 3 designs for each widget
              showing different granularity of data for users to customize.
            </styles.Description>
          </div>
          <div>
            <styles.Category>SKILLS UTILISED</styles.Category>
            <styles.CategoryList>
              Stakeholder management
              <br />
              User research
              <br />
              UI and interaction design
              <br />
              Feature prioritisation
              <br />
              Change management
              <br />
              Full stack development
              <br />
              Architechture design
              <br />
              Data engineering
            </styles.CategoryList>
            <styles.Category>COMPANY</styles.Category>
            <styles.CategoryList>Temasek International</styles.CategoryList>
          </div>
        </styles.DescriptionWrapper>
      </styles.RegularSection>
      <ImageCarousell
        images={imageList1}
        currentStep={currentStep1}
        setCurrentStep={setCurrentStep1}
        color={stripeTheme.red[90]}
      />
      <styles.RegularSection>
        <styles.ApproachWrapper>
          <styles.ApproachHeader>
            Oops, that's all you can see.
          </styles.ApproachHeader>
          <styles.GenericText>
            The rest of the info on the product is sensitive as it is an
            internal product. To view the rest of the project page, please
            <styles.ContactEmail
              data-replace="lirentang93@gmail.com"
              onClick={(e) => {
                window.location.href = "mailto:lirentang93@gmail.com";
                e.preventDefault();
              }}
            >
              <span>feel free to contact me.</span>
            </styles.ContactEmail>
          </styles.GenericText>
        </styles.ApproachWrapper>
      </styles.RegularSection>
      <NextProductSection
        name="TalentHub"
        themeColor={`radial-gradient(
          at bottom left,
          ${stripeTheme.red[90]},
          ${stripeTheme.red[80]},
          ${stripeTheme.purple[50]}
        )`}
        hoverColor={`radial-gradient(
          at bottom left,
          ${stripeTheme.purple[90]},
          ${stripeTheme.purple[80]},
          ${stripeTheme.red[50]}
        )`}
        route="/talenthub"
      />
    </Background>
  );
};
