import { motion } from "framer-motion";
import React from "react";
import { useSwipeable } from "react-swipeable";
import styled from "styled-components";
import { stripeTheme, typeScale } from "../../../themes";
import * as styles from "../sharedStyles";

const ColoredSection = styled(styles.ColoredSection)<{ color: string }>`
  background: ${(props) => props.color};
  position: relative;
`;

const AlternativeSection = styled.section<{ color: string }>`
  background: ${(props) => props.color};
  position: relative;
  padding: 48px 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const IntroImage = styled.img<{ isAlternative: boolean }>`
  height: ${(props) => (props.isAlternative ? "unset" : "70%")};
  width: ${(props) => (props.isAlternative ? "90%" : "unset")};
  border-radius: 8px;
  cursor: pointer;

  @media only screen and (max-width: 1440px) {
    height: ${(props) => (props.isAlternative ? "unset" : "70%")};
    width: ${(props) => (props.isAlternative ? "70%" : "unset")};
  }

  @media only screen and (max-width: 480px) {
    height: unset;
    width: 90%;
  }
`;

const ImageDescription = styled.span`
  margin: 16px 32px 0;
  color: ${stripeTheme.grey[20]};
  font-size: ${typeScale(-1)};
  text-align: center;

  @media only screen and (max-width: 480px) {
    font-size: ${typeScale(-2)};
  }
`;

const CarousellWrapper = styled.div`
  overflow: hidden;
  height: 100%;
`;

const AnimationWrapper = styled(motion.div)`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  height: 100%;
`;

const ImageWrapper = styled.div`
  flex: 0 0 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  justify-content: center;
`;

const Dot = styled.button<{ isSelected: boolean }>`
  background: ${(props) =>
    props.isSelected ? stripeTheme.grey[20] : stripeTheme.grey[60]};
  font-size: ${typeScale(4)};
  border: none;
  cursor: pointer;
  width: 52px;
  height: 4px;
  border-radius: 26px;

  :hover {
    font-weight: bold;
  }

  @media only screen and (max-width: 480px) {
    width: 24px;
    border-radius: 12px;
  }
`;

const Dots = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -32px;
  position: absolute;
  bottom: 20px;
  z-index: 1;
  gap: 16px;
`;

type ImageCarousellProps = {
  images: { img: string; desc: string }[];
  currentStep: number;
  setCurrentStep: (step: number) => void;
  color: string;
  isAlternative?: boolean;
};

export const ImageCarousell = ({
  images,
  currentStep,
  setCurrentStep,
  color,
  isAlternative = false,
}: ImageCarousellProps) => {
  const swipeHandler = useSwipeable({
    onSwipedLeft: () =>
      setCurrentStep(
        currentStep === images.length ? currentStep : currentStep + 1
      ),
    onSwipedRight: () =>
      setCurrentStep(currentStep === 1 ? currentStep : currentStep - 1),
    preventScrollOnSwipe: true,
    trackMouse: true,
  });

  const handleClick = () => {
    if (
      (!("ontouchstart" in document.documentElement) &&
        navigator.maxTouchPoints === 0) ||
      navigator.maxTouchPoints > 10
    ) {
      setCurrentStep(currentStep === images.length ? 1 : currentStep + 1);
    }
  };

  const content = (
    <>
      <Dots>
        {images.map((_, i) => (
          <Dot
            key={i}
            isSelected={i + 1 === currentStep}
            onClick={() => setCurrentStep(i + 1)}
          />
        ))}
      </Dots>
      <CarousellWrapper {...swipeHandler}>
        <AnimationWrapper
          animate={{ translateX: `${-100 * (currentStep - 1)}%` }}
          transition={{
            ease: "linear",
            duration: 0.3,
          }}
        >
          {images.map(({ img, desc }) => (
            <ImageWrapper key={desc}>
              <IntroImage
                src={img}
                onClick={handleClick}
                isAlternative={isAlternative}
              />
              <ImageDescription>{desc}</ImageDescription>
            </ImageWrapper>
          ))}
        </AnimationWrapper>
      </CarousellWrapper>
    </>
  );

  return isAlternative ? (
    <AlternativeSection color={color}>{content}</AlternativeSection>
  ) : (
    <ColoredSection color={color}>{content}</ColoredSection>
  );
};
