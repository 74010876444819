import { motion } from "framer-motion";
import React from "react";
import styled from "styled-components";
import { typeScale } from "../../themes";
import govTech from "../../assets/govtech-square.webp";
import temasek from "../../assets/temasek-square.webp";
import hopeTechnik from "../../assets/hope-technik-square.webp";

const Content = styled(motion.div)`
  margin-bottom: 120px;

  @media only screen and (max-width: 768px) {
    margin-bottom: 60px;
  }

  @media only screen and (max-width: 480px) {
    margin-bottom: 40px;
  }
`;

const Header = styled.h1`
  font-size: ${typeScale(4)};
  margin-bottom: 60px;

  @media only screen and (max-width: 768px) {
    font-size: ${typeScale(2)};
  }

  @media only screen and (max-width: 480px) {
    font-size: ${typeScale(0)};
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: start;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

const WorkBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  width: 280px;

  @media only screen and (max-width: 768px) {
    flex-direction: row;
    width: auto;
  }
`;

const WorkImage = styled.img`
  width: 80px;
  height: 80px;
  margin-bottom: 12px;
  border-radius: 4px;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;

  @media only screen and (max-width: 768px) {
    text-align: left;
    margin-left: 40px;
    align-self: start;
  }
`;

const WorkTitle = styled.span`
  font-weight: bold;
  margin-bottom: 4px;
`;

const WorkRole = styled.span`
  margin-bottom: 4px;
`;

const Line = styled.span`
  background: #32325d;
  width: 120px;
  height: 4px;
  margin-bottom: 90px;
  border-radius: 4px;
  align-self: center;

  @media only screen and (max-width: 768px) {
    width: 4px;
    height: 60px;
    margin: 16px 0 16px 38px;
    align-self: start;
  }
`;

const initialAnimation = { y: 50, opacity: 0 };
const postAnimation = {
  y: 0,
  opacity: 1,
  transition: {
    duration: 0.6,
  },
};

const viewPortOptions = { once: true, amount: 0.4 };

const workList = [
  {
    image: hopeTechnik,
    title: "Hope Technik",
    role: "Engineering Intern",
    dates: "2016",
    isLast: false,
  },
  {
    image: govTech,
    title: "GovTech, Government Digital Services",
    role: "Software Engineer",
    dates: "2017 - 2020",
    isLast: false,
  },
  {
    image: temasek,
    title: "Temasek International, Digital Technology",
    role: "Lead Technical Product Manager",
    dates: "2020 - 2024",
    isLast: false,
  },
  {
    image: govTech,
    title: "GovTech, Government Digital Products",
    role: "Lead Product Manager",
    dates: "2024 - present",
    isLast: true,
  },
];

export const WorkExperienceSection = () => {
  return (
    <Content
      initial={initialAnimation}
      whileInView={postAnimation}
      viewport={viewPortOptions}
    >
      <Header>My work experience so far</Header>
      <Container>
        {workList.map((work) => (
          <>
            <WorkBox>
              <WorkImage src={work.image} alt="govtech" />
              <TextContainer>
                <WorkTitle>{work.title}</WorkTitle>
                <WorkRole>{work.role}</WorkRole>
                <span>{work.dates}</span>
              </TextContainer>
            </WorkBox>
            {!work.isLast && <Line />}
          </>
        ))}
      </Container>
    </Content>
  );
};
