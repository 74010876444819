import React, { useState } from "react";
import styled from "styled-components";
import { stripeTheme, typeScale } from "../../themes";
import profile from "../../assets/profile.webp";
import climbing from "../../assets/climbing.webp";
import { motion } from "framer-motion";

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 48px;

  @media only screen and (max-width: 768px) {
    grid-template-columns: 1fr;
  }

  @media only screen and (max-width: 480px) {
    grid-template-columns: 1fr;
    grid-gap: 24px;
  }
`;

const AboutContent = styled(motion.p)`
  font-size: ${typeScale(2)};
  margin-bottom: 120px;
  margin-top: 0;

  @media only screen and (max-width: 768px) {
    font-size: ${typeScale(0)};
    margin-bottom: 60px;
  }

  @media only screen and (max-width: 480px) {
    font-size: ${typeScale(-2)};
    margin-bottom: 40px;
  }
`;

const Profile = styled(motion.img)`
  width: 85%;
  border-radius: 8px;

  @media only screen and (max-width: 768px) {
    width: 50%;
  }

  @media only screen and (max-width: 480px) {
    width: 100%;
    justify-self: center;
  }
`;

const MoreButton = styled.button`
  padding-left: 0;
  padding-right: 0;
  color: ${stripeTheme.grey[90]};
  overflow: hidden;
  font-family: inherit;
  margin-right: 36px;
  position: relative;
  display: inline-block;
  background: none;
  border: none;
  cursor: pointer;
  font-size: ${typeScale(1)};

  @media only screen and (max-width: 768px) {
    font-size: ${typeScale(0)};
  }

  @media only screen and (max-width: 480px) {
    font-size: ${typeScale(-2)};
  }

  ::before,
  ::after {
    content: "";
    position: absolute;
    width: 100%;
    left: 0;
  }

  ::after {
    content: attr(data-replace);
    height: 100%;
    top: 0;
    transform-origin: 100% 50%;
    transform: translate3d(0, 150%, 0);
    transition: transform 0.3s ease-in-out;
    color: ${stripeTheme.grey[60]};
  }

  :hover::after {
    transform: translate3d(0, 0, 0);
  }

  span {
    display: inline-block;
    transition: transform 0.3s ease-in-out;
  }

  :hover span {
    transform: translate3d(0%, -150%, 0);
  }
`;

const Header = styled(motion.h1)`
  font-size: ${typeScale(4)};

  @media only screen and (max-width: 768px) {
    font-size: ${typeScale(2)};
  }

  @media only screen and (max-width: 480px) {
    font-size: ${typeScale(0)};
  }
`;

const initialAnimation = { y: 50, opacity: 0 };
const animation = {
  y: 0,
  opacity: 1,
};
const transition = { duration: 0.6 };

export const AboutSection = () => {
  const [isWork, setIsWork] = useState(true);
  return isWork ? (
    <motion.div>
      <Header
        initial={initialAnimation}
        animate={animation}
        transition={transition}
      >
        👋🏼 Heya, nice to meet you.
      </Header>
      <Wrapper>
        <Profile
          src={profile}
          alt="profile"
          initial={initialAnimation}
          animate={animation}
          transition={transition}
        />
        <AboutContent
          initial={initialAnimation}
          animate={animation}
          transition={{ duration: 0.6, delay: 0.3 }}
        >
          Li Ren here, currently based in this little red dot of Singapore I
          call home. I am currently a lead technical product manager in the the
          Digital Economics Products Team in GovTech. Leading teams, on agile
          delivery and experimentations, while seeking alignment and refining
          technical implementations with overarching business and policy
          objectives.
          <br />
          <br />
          In my past life, I was in the Digital Technology Team of Temasek
          International enabling better investment decisions through AI and
          user-centered digital solutions, helping support teams improve their
          processes, and evangelizing an agile mindset throughout the
          organization.
          <br />
          <br />
          Prior to that I was a software engineer in Government Digital Services
          also from GovTech solving problems for Singapore citizens through
          innovative and trustworthy solutioning. There I worked with the
          financial, social, manpower sectors. In cross-functional teams, I led
          and implemented technical decisions from feature development to
          architecture and software design. I have also led user research of
          products and drove agile culture in the team.
          <br />
          <br />
          I've worked on products at all parts of the lifecycle, and built and
          led teams from conceptualisation to scaling. And I am experienced in
          product management, coding, designing, user research, and coaching in
          a team. Outside of the product space, my other responsibilities and
          experiences include team budgeting and financials, partner
          relationship management, change management and some others...
          <br />
          <br />
          <MoreButton
            onClick={() => {
              setIsWork(false);
            }}
            data-replace="But what about outside of work? 🤔 →"
          >
            <span>But what about outside of work? 🤔 →</span>
          </MoreButton>
        </AboutContent>
      </Wrapper>
    </motion.div>
  ) : (
    <>
      <Header
        initial={initialAnimation}
        animate={animation}
        transition={transition}
      >
        👋🏼 Heya, it's still me!
      </Header>
      <Wrapper>
        <Profile
          src={climbing}
          alt="profile"
          initial={initialAnimation}
          animate={animation}
          transition={transition}
        />
        <AboutContent
          initial={initialAnimation}
          animate={animation}
          transition={{ duration: 0.6, delay: 0.3 }}
        >
          Outside of work, I am someone who loves creating with his hands. I
          enjoy trying out new things and prefer testing and experimenting out
          over learning from theory. You can often find me in climbing gyms in
          Singapore bouldering, which I have been doing since 2017. When I'm not
          on the walls I am usually at home cooking, where I occasionally host
          friends by cooking a multi course dinner. I also enjoy playing guitar,
          singing and making music in my free time.
          <br />
          <br />
          I'm a strong advocate for giving back to my alma mater, and am very
          involved in the activities of my university, Singapore University of
          Technology and Design. I am part of the founding committee of the SUTD
          Alumni Association and was the first Vice President of the
          organisation.
          <br />
          <br />
          I enjoy growing the younger generation and occasionally conduct
          lectures and give talks, usually at universities as a guest lecturer
          or as an invited speaker. I have also been involved in the creation
          and running of coding workshops for lesser fortunate kids, where I
          have kickstarted similar initiatives for my workplaces and collegues.
          <br />
          <br />
          <MoreButton
            onClick={() => {
              setIsWork(true);
            }}
            data-replace="Back to work stuff! 👨‍💻 →"
          >
            <span>Back to work stuff! 👨‍💻 →</span>
          </MoreButton>
        </AboutContent>
      </Wrapper>
    </>
  );
};
