import { motion } from "framer-motion";
import React from "react";
import styled from "styled-components";
import * as styles from "../sharedStyles";

const TopSection = styled(motion.section)<{ themeColor: string }>`
  background: ${(props) => props.themeColor};
  height: calc(100vh - 40px);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

type TitleSectionProps = {
  name: string;
  themeColor: string;
};

export const TitleSection = ({ name, themeColor }: TitleSectionProps) => {
  return (
    <TopSection
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      themeColor={themeColor}
    >
      <styles.MainTitle
        initial={{ y: 50, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.5, delay: 0.5 }}
      >
        {name}
      </styles.MainTitle>
    </TopSection>
  );
};
