import wins from "../../assets/projects/wins/homepage.webp";
import mymoneysense from "../../assets/projects/mymoneysense/what_can_you_do.webp";
import supplyally from "../../assets/projects/SupplyAlly/homepage.webp";
import tgini from "../../assets/projects/tgini/gini_homepage.webp";
import winnow from "../../assets/projects/winnow/homepage.webp";
import talenthub from "../../assets/projects/talenthub/360feedback_home.webp";
import onecv from "../../assets/projects/onecv/onecv.webp";

export const projectList = [
  {
    url: "wins",
    title: "Workpass integration System (WINS)",
    description:
      "Singapore families have always had a high reliance on foreign domestic workers (FDW) for help in the household. WINS allows FDW employers and agencies to interact with the Ministry of Manpower (MOM) on all matters related to FDW permits. And with the increasing necessity of live-in domestic help in Singapore, we digitised and modernised the solution to be the multi-touchpoint experience for families to manage their helpers’ work permits.",
    howMightWe:
      "How might we provide a clearer and fuss-free experience during policy changes and help employees manage their helper's work permits?",
    image: wins,
  },
  {
    url: "mymoneysense",
    title: "MyMoneySense | SGFinDex",
    description:
      "Despite Singapore’s relatively high GDP per capita, the Singaporeans were not managing their finances well. Most had misconceptions about financial planning, more than 50% of Singaporeans were not insured well enough, and 2/3 did not have enough savings to last them more than 6 months. We enabled Singaporeans to better understand their finances and take action to improve their financial well-being across different life stages. Providing trusted, personalised and actionable guidance making financial planning accessible for everyone.",
    howMightWe:
      "How might we help young working adults better understand their financial goals and plan towards them?",
    image: mymoneysense,
  },
  {
    url: "onecv",
    title: "Short reflection on: One Client View",
    description:
      "Traditionally the disbursing help in the social sector has always been arduous and slow. Each agency would house their own data in silos and their triaging systems didn’t communicate. We built a system to facilitate more effective conversations and support between social frontline officers and clients. Allowing them to obtain a comprehensive view of clients’ circumstances and assistance received. Officers can now gather information with ease and render support promptly, while clients need not resubmit common documents.",
    howMightWe:
      "How might we lower cognitive load of clients when recieving social aid?",
    image: onecv,
  },
  {
    url: "supplyally",
    title: "Short reflection on: SupplyAlly",
    description:
      "During the early days of COVID-19 there was a need to distribute various goods from the government to the citizens of Singapore. The need started with masks, and there was an urgent need to get something out fast. In 4 days, a bootstrap team was pulled together and we made a product built off a NoSQL and serverless solution ensuring that we would be able to pivot the solution as required. ",
    howMightWe:
      "How might we prevent duplicates and enable a trusted and scalable distribution of items?",
    image: supplyally,
  },
  {
    url: "tgini",
    title: "T-Gini",
    description:
      "Time is wasted filtering and sifting through verified sources for important and relevant news articles and research papers to keep up to date to the latest developments in their domain. Sharing of this type of info was also ineffective and not standardised. This led to asynchronous information affecting downstream decisions like deal origination and policy changes. We developed an ML-driven web app that consolidates research and news articles from 100+ sources across multiple domains. Utilising AI/ML to categorise and push subscription-based relevant updates to facilitate better investment decisions and enable foresight in determining pre-emptive measures.",
    howMightWe:
      "How might we improve reading habits and improve discoverability of relevant news and research?",
    image: tgini,
  },
  {
    url: "winnow",
    title: "Winnow",
    description:
      "Investment teams utilise many sources of data to aid in their research on companies, from internal and external sources to free and paid data providers. Too much time is spent individually piecing data together to form a holistic picture of a company, and even when they do so, they often cannot compare the data with other peers. Our solution is a modular widget based platform focused on connecting and making sense of data from varying sources. Providing analytics provided through these multiple data sources, we aid teams when researching companies for deal origination, due diligence, and portfolio monitoring.",
    howMightWe:
      "How might we link company data together from multiple sources to provide investment teams with relevant analytics for their deal research?",
    image: winnow,
  },
  {
    url: "talenthub",
    title: "TalentHub",
    description:
      "Currently the performance and feedback review cycle is done once a year. Leaving individuals dreading spending a bulk of their time at the end of the year going through the cycle. Feedback is also very biased towards year end projects, and managers have a lack of visibility of data to provide useful learning goals and plans for their team.",
    howMightWe:
      "How might we empower individuals with the tools and means to provide timely, specific and incentivized feedback and growth plans?",
    image: talenthub,
  },
];
