import React from "react";
import styled from "styled-components";

const Container = styled.div`
  box-sizing: border-box;
  position: relative;
  width: 595px;
  height: 842px;
  margin: 0 auto;
  background: #ffffff;
  * {
    font-family: "Roboto Condensed";
  }
  border: 0px solid white;
`;

const TopPanel = styled.div`
  width: 100%;
  background: #4f4f4f;
`;

const Name = styled.div`
  font-size: 20px;
  line-height: 20px;
  color: #ffffff;
  padding: 10px 0;
  text-align: center;
`;

const Website = styled.div`
  font-size: 12px;
`;

const LastUpdated = styled.div`
  position: absolute;
  right: 6px;
  top: 6px;
  font-size: 8px;
  line-height: 9px;
  color: #828282;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 28px;
  padding: 32px 32px;
  background: #ffffff;
`;

const SideBar = styled.div`
  width: 103px;
`;

const RightPart = styled.div`
  margin-top: 1px;
  color: #333333;
`;

const Skills = styled.div`
  width: 103px;
  font-size: 9px;
  text-align: right;
  margin-bottom: 10px;
  line-height: 11px;
  color: #333333;
`;

const Section = styled.div`
  margin-bottom: 6px;
`;

const Header = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
`;

const Text = styled.div`
  font-size: 9px;
  line-height: 11px;
`;

const Flex = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const PurpleText = styled.div`
  font-size: 9px;
  line-height: 11px;
  text-align: right;
  color: #9b51e0;
`;

const List = styled.ul`
  margin: 0;
  padding: 0 0 0 14px;
  > li {
    list-style-type: none;
    position: relative;
  }
  > li::before {
    content: "•";
    position: absolute;
    left: -8px;
    font-size: 12px;
    top: -1.5px;
  }
`;

const ColorWrapper = styled.div`
  color: #333333;
`;

export const ResumePage = () => {
  return (
    <>
      <Container>
        <TopPanel>
          <Name>
            LiRen<b>Tang</b>
            <Website>https://lirentang.me/hello/msf</Website>
          </Name>
          <LastUpdated>Last updated - Oct 2024</LastUpdated>
        </TopPanel>
        <Wrapper style={{ paddingBottom: "0" }}>
          <SideBar>
            <Skills>
              <b style={{ fontSize: "12px", lineHeight: "14px" }}>skills</b>
              <br />
              <b>Product development</b>
              <br />
              Scrum, LeSS, Co-Active Coaching, Workshop Facilitation, Design
              Thinking, UX Research
              <br />
              <b>Engineering</b>
              <br />
              Typescript, HTML, CSS, Python, Golang, Ruby on Rails, React,
              D3.js, NodeJS, Selenium, SQL, Travis, GoCD, Dagster, Airflow
            </Skills>
            <Skills>
              <b style={{ fontSize: "12px", lineHeight: "14px" }}>contact</b>
              <br />
              Currently in Singapore 🇸🇬
              <br />
              +65 96247612
              <br />
              lirentang93@gmail.com
            </Skills>
          </SideBar>
          <RightPart>
            <Section>
              <Header>
                <b style={{ color: "#2D9CDB" }}>abo</b>ut me
              </Header>
              <Text>
                Oh hello :) I am a problem solver and a proven leader, with
                great passion in an agile (not Agile) approach to products to
                deliver a user-centered experience. Experienced in product
                management, coding, designing, user research, and coaching in a
                team. I am naturally curious and am experienced in all parts of
                the product life cycle, especially starting new products and
                building cultures and teams from ground up. I have delivered
                multiple AI/ML products across investment, HR, and research
                domains and have a strong passion in developing and designing
                for social good and harnessing the power of the community and
                teams to solve the puzzles of our complex world.
              </Text>
            </Section>
            <Section>
              <Header>
                <b style={{ color: "#219653" }}>edu</b>cation
              </Header>
              <Flex>
                <Text>
                  <b>B.Eng</b>, Engineering Product Development
                </Text>
                <PurpleText>
                  Singapore University of Technology and Design | 2014 - 2017
                </PurpleText>
              </Flex>
              <Text>
                Specialised in Biomedical and Mechanical Engineering. Vice
                President of Student Government
              </Text>
            </Section>
            <Section>
              <Header>
                <b style={{ color: "#F2994A" }}>app</b>ointments
              </Header>
              <Flex>
                <Text>
                  <b>SUTD Alumni Association</b>
                </Text>
                <PurpleText>
                  Singapore University of Technology and Design Alumni
                  Association | 2019 - 2021
                </PurpleText>
              </Flex>
              <Text>Vice President and Founding Member</Text>
            </Section>
          </RightPart>
        </Wrapper>
        <Wrapper style={{ paddingTop: "0" }}>
          <Section>
            <Header>
              <b style={{ color: "#EB5757" }}>exp</b>erience
            </Header>
            <Text style={{ marginBottom: "16px" }}>
              <div style={{ fontSize: "11px", lineHeight: "13px" }}>
                <b style={{ color: "#9B51E0" }}>GovTech</b>, Lead Product
                Manager <i>(2024 - Present)</i> | Software Engineer{" "}
                <i>(2017 - 2020)</i>
              </div>
              Responsible for driving digital transformation and evangelises
              agile culture in the public sector. Experienced in working with
              the financial, social, manpower sectors. I have led
              cross-functional teams to deliver ambiguous products from
              conceptualisation to maintenance, while seeking alignment and
              refining technical implementations and overarching business and
              policy objectives. I have also led and implemented technical
              decisions from feature development to architecture and software
              design, as well as user research of products and drove agile
              culture in the team.
            </Text>
            <Flex>
              <Text>
                <b>CareerHealth SG</b>, Lead Product Manager
              </Text>
              <PurpleText>
                GovTech x Ministry of Manpower x SkillsFuture Singapore x
                Workforce Singapore | 2024
              </PurpleText>
            </Flex>
            <Text style={{ marginBottom: "16px" }}>
              CareerHealth SG is a programme born from the ForwardSG movement.
              Its goals are to improve Singapore's economy through successful
              labour market outcomes such as increased career mobility and
              clarity. One of the main products is the Career & Skills Passport,
              a website for individuals that holds their government verified
              career and skills data. Helping users take stock of their current
              career situation and allowing users to share their government
              verified records to employers to standout during the job
              application process.
              <List>
                <li>
                  Mentored and led development team as the overall lead which
                  includes 3 other product managers. Defined product outcomes,
                  built a culture of experimentation, and a data driven agile
                  development approach through iterations and experimentations.
                  Working towards making government verified records available
                  to ~2M citizens in the workforce
                </li>
                <li>
                  Led and fronted partnerships with 4 online job portals to
                  share government verified information for individuals' job
                  applications
                </li>
                <li>
                  Proved hypothesis that bringing government verified
                  information earlier in the job hiring funnel would make
                  individuals standout in the shortlisting process. Experimented
                  with live employers, producing a 14% increase in chances of
                  being shortlisted
                </li>
              </List>
            </Text>
            <Flex>
              <Text>
                <b>One Client View</b>, Software Engineer
              </Text>
              <PurpleText>
                GovTech x Ministry of Social and Family Development | 2019 -
                2020
              </PurpleText>
            </Flex>
            <Text style={{ marginBottom: "16px" }}>
              Facilitating more effective conversations and support between
              social frontline officers and clients. Allowing them to obtain a
              common comprehensive view of clients’ circumstances and assistance
              received pulled from varying sources.
              <List>
                <li>
                  {`Led implementation of the system from conceptualization, helping >2,500 users gather information faster for 20% of Singaporeans who receive social aid while they need not resubmit common documents leading to a significant reduction in triaging time`}
                </li>
                <li>
                  Co-led user research and synthesized user requirements across
                  20+ agencies. Provided guidance on feature ideation and UI/UX
                </li>
                <li>
                  Facilitated workshops across 30+ stakeholders to agree on data
                  sharing models and feature prioritization, aligning product
                  vision with user needs through quick iterative releases
                </li>
              </List>
            </Text>
            <Flex>
              <Text>
                <b>SupplyAlly</b>, Software Engineer
              </Text>
              <PurpleText>GovTech | 2020</PurpleText>
            </Flex>
            <Text style={{ marginBottom: "16px" }}>
              A flexible lightweight mobile solution to enable verifiable and
              auditable dispersions of physical goods to individuals and
              families. With the lack of need for paper lists and manual
              checking for eligibility or duplications, we cut down time of the
              distribution drastically. Used during early COVID-19 days to
              facilitate mask, sanitiser, and laptop distribution.
              <List>
                <li>
                  {`Developed in a quick iterative fashion with a server-less architecture with NoSQL to scale the product to multiple domains easily eventually being used across 100+ instances helping to distribute >4.2M reusable masks`}
                </li>
                <li>
                  Led development of authorisation and authentication that was
                  scalable with the size of the current and 100+ future
                  concurrent instances
                </li>
              </List>
            </Text>
            <Flex>
              <Text>
                <b>MyMoneySense & SGFinDex</b>, Software Engineer
              </Text>
              <PurpleText>
                GovTech x Ministry of Manpower | 2018 - 2020
              </PurpleText>
            </Flex>
            <Text style={{ marginBottom: "16px" }}>
              Mobile-responsive web app that encourages planning for one’s
              financial future by visualising the interplay between spending and
              saving. Providing trusted, personalised and actionable guidance
              making financial planning accessible for everyone.
              <List>
                <li>
                  Positioned at the intersection of business, design, and
                  development to bring product from inception to release.
                </li>
                <li>
                  Conducted user research, wireframing, organized design
                  workshops, and developed iterations of highly interactive
                  prototypes
                </li>
                <li>
                  Worked with stakeholders across the public and private sector
                  and led and coached the team to build our agile culture and to
                  transition to modern product development and engineering
                  practices like iterative product development, containers and
                  cloud infrastructure
                </li>
                <li>
                  Drove technical decisions such as languages and frameworks,
                  solution architecture design and engineering best practices
                </li>
                <li>
                  Conceptualized and laid groundwork for technical architectures
                  and resolved roadblocks during negotiations for SGFinDex, the
                  world’s first public digital infrastructure that enables
                  individuals to access financial information held across
                  government agencies and financial institutions
                </li>
              </List>
            </Text>
            <Flex>
              <Text>
                <b>Work Pass Integrated Systems</b>, Software Engineer
              </Text>
              <PurpleText>
                GovTech x Ministry of Manpower | 2017 - 2018
              </PurpleText>
            </Flex>
            <Text>
              The system allows employers of foreign domestic workers to perform
              Work Permit transactions.
              <List>
                <li>
                  {`Developed features and maintained a live system serving >300,000 users undergoing transition from a legacy to a new system`}
                </li>
                <li>
                  {`Led the software design and implementation of transitioning payments gateway from CyberSource to Braintree, improving the user flow and significantly reducing failed transactions, facilitating >150,000 transactions per year`}
                </li>
                <li>
                  Evangelized agile methodology and drove strong development
                  practices like TDD, CI/CD, pair programming, and rigorous E2E
                  smoke testing
                </li>
              </List>
            </Text>
          </Section>
        </Wrapper>
      </Container>
      <br />
      <br />
      <br />
      <br /> <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <Container>
        <ColorWrapper>
          <Wrapper>
            <div>
              <Text style={{ marginBottom: "16px" }}>
                <div style={{ fontSize: "11px", lineHeight: "13px" }}>
                  <b style={{ color: "#9B51E0" }}>Temasek International</b>,
                  Lead Product Manager (Technical) <i>(2020 - 2024)</i>
                </div>
                Responsible for in-house data analytics products that confer
                strategic advantages in deal screening, due diligence, portfolio
                monitoring and talent development. Improve investment decisions
                and workflows leveraging AI/ML and user-centered digital
                solutions. In cross-functional teams, I own the product life
                cycle and process, managing, designing and building products
                from inception to maintenance. Lead product decisions, user
                research, feature priority, and drive technical decisions and
                feature development. Drive organizational change and mentor
                others to empower teams prioritizing happiness and growth.
                Manage user, vendor and stakeholder relationships, negotiating
                terms, scope, contracting, and deadlines.
              </Text>
              <Flex>
                <Text>
                  <b>TalentHub</b>, Product Lead
                </Text>
                <PurpleText>Temasek International | 2021 - 2024</PurpleText>
              </Flex>
              <Text style={{ marginBottom: "16px" }}>
                A collection of products focused on providing analytics and
                services based on external and internal talent data. Driven by
                AI to classify skills and summarise feedback. Enables HR and
                managers to facilitate continuous feedback and employee growth
                to build a stronger feedback culture and to recommend board
                members for portfolio companies.
                <List>
                  <li>
                    Mentored and led the team as overall product lead across 3
                    products, balancing stakeholder management, budgeting, user
                    needs, and prioritising features across workstreams from
                    inception to releases
                  </li>
                  <li>
                    Pioneered a platform and feature sharing approach across
                    workstreams for common features like RBAC, data pipelines,
                    etc, decreasing development time by up to 45%
                  </li>
                  <li>
                    Delievered a talent system of record which includes skill
                    matching, AI driven skill classification, dynamic search,
                    and customizable report generation, increasing visibility of
                    talents exponentially, reducing operational changes lead
                    time from 2 months to a few minutes and increasing reporting
                    speed by 5x
                  </li>
                  <li>
                    Integrated with SuccessFactors via ETL pipelines for
                    analytics and visualization of talent data. Enabled trend
                    forecasting and comparisons of employees for better policy
                    decisions and quicker interventions
                  </li>
                  <li>
                    Led development of an employee review system with a reach of
                    the entire institution, replacing a previous SuccessFactors
                    custom module, saving $230k annually and increased system
                    availability by 25%
                  </li>
                  <li>
                    Lead a product to shift the perception of feedback to be
                    project-based and continuous, to replace the need for a
                    year-end feedback cycle, improving feedback culture and
                    inculcating a growth mindset.
                  </li>
                </List>
              </Text>
              <Flex>
                <Text>
                  <b>T-Gini</b>, Product Lead
                </Text>
                <PurpleText>Temasek International | 2020 - 2024</PurpleText>
              </Flex>
              <Text style={{ marginBottom: "16px" }}>
                {`ML-driven web app that consolidates >50m research and news articles from 100+ sources across multiple domains. Utilizes ML to categorize and push subscription-based relevant updates to facilitate better investment decisions and enable foresight in determining pre-emptive measures for policies.`}
                <List>
                  <li>
                    Instituted a platform approach to the product, reducing time
                    taken to onboard new domains by 4x and an additional 80%
                    savings on development costs
                  </li>
                  <li>
                    Launched recommendations and search term watchlist features
                    that help prioritize and surface most relevant developments
                    for users, reducing time spent on competitor tracking by 20%
                  </li>
                  <li>
                    {`Led user research and delivered several social features from conceptualization, allowing users to interact with each other within context of the app, increasing user retention rate by >80%`}
                  </li>
                  <li>
                    Navigated through fast feature iterations to aid investment
                    decisions in COVID-19 research, including Twitter sentiment
                    analysis and ingesting more than 50x previously expected
                    papers. Leading directly to 3 new investments that
                    contributed to Temasek’s largest returns in 10 years of 43%
                  </li>
                </List>
              </Text>
              <Flex>
                <Text>
                  <b>Winnow</b>, Co-Product Lead
                </Text>
                <PurpleText>Temasek International | 2021 - 2022</PurpleText>
              </Flex>
              <Text style={{ marginBottom: "16px" }}>
                Harmonizing company data from 30+ internal and external sources
                providing users with insights on a single pane of glass via
                domain shareable widgets. Enabling more informed investment
                decisions, with financial, organizational, and sentiment data at
                users’ fingertips decreasing research time significantly.
                <List>
                  <li>
                    Led expansion of product to new group of users with new
                    requirements and data sources including the first
                    integration with Sharepoint to meld internal financial data
                    with external real-time company drug products release data.
                  </li>
                  <li>
                    Launched features such as a company watchlist and
                    side-by-side comparisons for teams to share a common source
                    of truth of data and decreasing time taken for investment
                    due diligence by 30%
                  </li>
                  <li>
                    Evangelized and enabled the use of alternative data in the
                    investment research workflow like sentiment analysis, google
                    trends, hiring and attrition rates for a more comprehensive
                    outlook on companies, so far leading to multiple new
                    investments in the technology domain
                  </li>
                  <li>
                    Delivered a suite of AI/ML features leveraging NLP to
                    categorize companies and recommend competitors surfacing
                    unknown companies for new deal origination and portfolio
                    monitoring
                  </li>
                </List>
              </Text>
              <Flex>
                <Text>
                  <b>Carbon & Sustainability Analytics</b>, Product Lead
                </Text>
                <PurpleText>Temasek International | 2021 - 2022</PurpleText>
              </Flex>
              <Text style={{ marginBottom: "16px" }}>
                An in-house built web app for storing, automating calculations,
                and visualizing ESG data of companies. Worked on
                institutionalizing models and trusted data sources for
                carbon-related data.
                <List>
                  <li>
                    {`Launched a self-service data marketplace for users to analyse and export ESG data, increasing accessibility of data from 4 to 300+ employees and reducing ESG due diligence time by >300%`}
                  </li>
                  <li>
                    Integrated solution with QlikSense for automated ESG
                    reporting for visualisations to enable forecasts, while
                    institutionalizing the company’s first-ever ESG reporting
                    metrics
                  </li>
                  <li>
                    Led discussions with S&P to provide carbon data of companies
                    and pioneered data querying through Snowflake’s data
                    marketplace, institutionalizing the process for other
                    datasets to do the same
                  </li>
                </List>
              </Text>
            </div>
          </Wrapper>
        </ColorWrapper>
      </Container>
    </>
  );
};
