import { motion } from "framer-motion";
import React from "react";
import styled from "styled-components";
import { typeScale } from "../../themes";
import { AboutSection } from "./AboutSection";
import { ValuesSection } from "./ValuesSection";
import { OthersSection } from "./OthersSection";
import { WorkExperienceSection } from "./WorkExperienceSection";

const Background = styled.div`
  width: 100vw;
`;

const Wrapper = styled.div`
  max-width: 1440px;
  padding: 0 120px;
  margin: 120px auto;

  @media only screen and (max-width: 768px) {
    padding: 0 60px;
    margin: 80px auto;
  }

  @media only screen and (max-width: 480px) {
    padding: 0 40px;
    margin: 64px auto;
  }
`;

const Preamble = styled(motion.h2)`
  font-size: ${typeScale(3)};
  margin: 60px 0;

  @media only screen and (max-width: 768px) {
    font-size: ${typeScale(1)};
  }

  @media only screen and (max-width: 480px) {
    font-size: ${typeScale(-1)};
    margin: 40px 0;
    text-align: center;
  }
`;

export const AboutPage = () => {
  return (
    <Background>
      <Wrapper>
        <Preamble
          initial={{ y: 50, opacity: 0 }}
          animate={{
            y: 0,
            opacity: 1,
            transition: {
              duration: 0.8,
            },
          }}
          transition={{ duration: 0.8 }}
        >
          A problem solver and a proven leader, with great passion in an agile
          (not Agile) approach to delivering products and building teams to
          produce outcomes no individual can achieve alone. As a product manager
          and team lead, when given the choice I believe, prioritising the team
          makes a better product, and focusing on processes produces better
          results.
        </Preamble>
        <AboutSection />
        <WorkExperienceSection />
        <ValuesSection />
        <OthersSection />
      </Wrapper>
    </Background>
  );
};
