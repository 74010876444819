import { motion } from "framer-motion";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import styled from "styled-components";
import { stripeTheme, typeScale } from "../../themes";
import { useIsInViewport } from "../../utilities";
import { projectList } from "./ProjectsConstants";

const Background = styled.div<{ background: string }>`
  width: 100vw;
  height: 100vh;
  position: fixed;
  background: ${(props) => props.background};
  overflow: scroll;
  transition: 300ms ease-in-out;
`;

const Wrapper = styled(motion.div)`
  width: 100vw;
  height: 100vh;
  padding: 0 150px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${stripeTheme.grey[20]};
  text-align: left;

  @media only screen and (max-width: 1200px) {
    text-align: center;
    justify-content: center;
    flex-direction: column-reverse;
    margin-top: 120px;
    padding: 0 48px;
  }

  @media only screen and (max-width: 480px) {
    padding: 0 40px;
    margin-top: 48px;
  }
`;

const Title = styled.div`
  font-weight: bold;
  font-size: ${typeScale(10)};
  margin-bottom: 12px;

  @media only screen and (max-width: 1200px) {
    font-size: ${typeScale(6)};
  }

  @media only screen and (max-width: 480px) {
    font-size: ${typeScale(4)};
  }
`;

const SubTitle = styled.div`
  font-style: italic;
  font-size: ${typeScale(1)};
  margin-bottom: 40px;

  @media only screen and (max-width: 1200px) {
    font-size: ${typeScale(0)};
    margin-bottom: 32px;
  }

  @media only screen and (max-width: 480px) {
    font-size: ${typeScale(-2)};
    margin-bottom: 24px;
  }
`;

const Description = styled.div`
  font-size: ${typeScale(3)};
  width: 80%;
  margin-bottom: 32px;

  @media only screen and (max-width: 1200px) {
    font-size: ${typeScale(1)};
    margin-bottom: 24px;
    width: 100%;
  }

  @media only screen and (max-width: 480px) {
    font-size: ${typeScale(-1)};
    width: 100%;
  }
`;

const ReadMoreButton = styled.button`
  overflow: hidden;
  font-family: inherit;
  position: relative;
  display: inline-block;
  background: none;
  border: none;
  cursor: pointer;
  color: ${stripeTheme.grey[20]};
  font-size: ${typeScale(1)};

  @media only screen and (max-width: 1200px) {
    font-size: ${typeScale(0)};
  }

  @media only screen and (max-width: 480px) {
    font-size: ${typeScale(-2)};
  }

  ::before,
  ::after {
    content: "";
    position: absolute;
    @media only screen and (max-width: 1200px) {
      width: 100%;
    }
    left: 0;
  }

  ::after {
    content: attr(data-replace);
    position: absolute;
    height: 100%;
    top: 0;
    transform-origin: 100% 50%;
    transform: translate3d(0, 150%, 0);
    transition: transform 0.3s ease-in-out;
  }

  :hover::after {
    transform: translate3d(0, 0, 0);
  }

  span {
    display: inline-block;
    transition: transform 0.3s ease-in-out;
  }

  :hover span {
    transform: translate3d(0%, -150%, 0);
  }
`;

const ContentWrapper = styled.div`
  width: 45%;

  @media only screen and (max-width: 1200px) {
    width: 100%;
    margin-top: 24px;
  }

  @media only screen and (max-width: 480px) {
    width: 100%;
  }
`;

const Photo = styled.img`
  width: 50%;
  justify-self: end;
  cursor: pointer;

  @media only screen and (max-width: 1200px) {
    width: 80%;
    justify-self: unset;
  }

  @media only screen and (max-width: 480px) {
    width: 80%;
  }
`;

const initialAnimation = { y: 50, opacity: 0 };
const postAnimation = {
  y: 0,
  opacity: 1,
  transition: {
    duration: 0.6,
  },
};
const viewPortOptions = { once: true, amount: 0.4 };

export const WorkListPage = () => {
  const navigate = useNavigate();
  const [background, setBackground] = useState(stripeTheme.blue[80]);
  let refs = useRef(projectList.map((_) => React.createRef<HTMLDivElement>()));

  const isWinsInViewport = useIsInViewport(refs.current[0]);
  const isMyMoneySenseInViewport = useIsInViewport(refs.current[1]);
  const isOneCVViewport = useIsInViewport(refs.current[2]);
  const isSupplyAllyInViewport = useIsInViewport(refs.current[3]);
  const isTGiniInViewport = useIsInViewport(refs.current[4]);
  const isWinnowInViewport = useIsInViewport(refs.current[5]);
  const isTalentHubInViewport = useIsInViewport(refs.current[6]);

  useEffect(() => {
    if (isWinsInViewport) {
      setBackground(stripeTheme.blue[80]);
    }
    if (isMyMoneySenseInViewport) {
      setBackground(stripeTheme.cyan[80]);
    }
    if (isOneCVViewport) {
      setBackground(stripeTheme.green[80]);
    }
    if (isSupplyAllyInViewport) {
      setBackground(stripeTheme.yellow[80]);
    }
    if (isTGiniInViewport) {
      setBackground(stripeTheme.orange[80]);
    }
    if (isWinnowInViewport) {
      setBackground(stripeTheme.red[80]);
    }
    if (isTalentHubInViewport) {
      setBackground(stripeTheme.purple[80]);
    }
  }, [
    isWinsInViewport,
    isMyMoneySenseInViewport,
    isOneCVViewport,
    isSupplyAllyInViewport,
    isTGiniInViewport,
    isWinnowInViewport,
    isTalentHubInViewport,
  ]);

  const viewProjectDetails = (url: string) => {
    navigate(`/work/${url}`);
  };

  return (
    <Background background={background}>
      {projectList.map((project, index) => (
        <Wrapper
          key={index}
          initial={initialAnimation}
          whileInView={postAnimation}
          viewport={viewPortOptions}
        >
          <ContentWrapper>
            <Title>{project.title}.</Title>
            <SubTitle>"{project.howMightWe}"</SubTitle>
            <Description ref={refs.current[index]}>
              {project.description}
            </Description>
            {project.url === "onecv" ? (
              <ReadMoreButton data-replace="Coming soon!">
                <span>🚧 Project page under construction 🚧</span>
              </ReadMoreButton>
            ) : project.url === "tgini" ||
              project.url === "winnow" ||
              project.url === "talenthub" ? (
              <ReadMoreButton
                onClick={() => viewProjectDetails(project.url)}
                data-replace="Take me there!"
              >
                <span>View limited project info →</span>
              </ReadMoreButton>
            ) : (
              <ReadMoreButton
                onClick={() => viewProjectDetails(project.url)}
                data-replace="Off we go!"
              >
                <span>Read More →</span>
              </ReadMoreButton>
            )}
          </ContentWrapper>
          <Photo
            src={project.image}
            alt={project.title}
            onClick={() => viewProjectDetails(project.url)}
          />
        </Wrapper>
      ))}
    </Background>
  );
};
