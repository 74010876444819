import React, { useRef, useEffect, useState } from "react";
import styled from "styled-components";
import { stripeTheme } from "../../themes";
import * as styles from "./sharedStyles";
import logo from "../../assets/temasek-logo.webp";
import { ProductsNavBar } from "./SharedComponents/ProductsNavBar";
import { useScroll } from "framer-motion";
import { NextProductSection } from "./SharedComponents/NextProductSection";
import { TitleSection } from "./SharedComponents/TitleSection";
import mainImage from "../../assets/projects/tgini/gini_homepage.webp";

const Background = styled(styles.Background)`
  color: ${stripeTheme.orange[100]};
`;

export const TGini = () => {
  const hiddenRef = useRef<HTMLDivElement>(null);
  const overviewRef = useRef<HTMLElement>(null);
  const [refLocation, setRefLocation] = useState(0);

  const { scrollYProgress } = useScroll({
    target: hiddenRef,
    offset: ["end end", "start start"],
  });

  useEffect(() => {
    if (scrollYProgress.get() !== 0) {
      setRefLocation(scrollYProgress.get());
    }
    scrollYProgress.onChange((v) => {
      setRefLocation(v);
    });
  }, [scrollYProgress]);

  return (
    <Background>
      <ProductsNavBar
        refs={{
          hiddenRef: hiddenRef,
          overviewRef: overviewRef,
        }}
        companyLogo={logo}
        refLocation={refLocation}
        logoSize="small"
      />
      <TitleSection
        themeColor={`radial-gradient(
          at bottom left,
          ${stripeTheme.orange[90]},
          ${stripeTheme.orange[80]},
          ${stripeTheme.red[50]}
        )`}
        name="T-Gini"
      />
      <styles.RegularSection ref={overviewRef}>
        <styles.HiddenRefDiv ref={hiddenRef} />
        <styles.MainImage src={mainImage} />
        <styles.SubTitle>
          Facilitating better investment decisions
          <br />
          by encouraging sharing of knowledge and targeted research.
        </styles.SubTitle>
        <styles.DescriptionWrapper>
          <div>
            <styles.Description>
              In the past Temasek’s internal department teams spend a lot of
              time filtering and sifting through verified sources for important
              and relevant news articles and research papers to keep up to date
              to the latest developments in their domain. Sharing of this type
              of info was also ineffective and not standardised. This led to
              asynchronous information affecting downstream decisions like deal
              origination and policy changes.
            </styles.Description>
            <styles.Category>MY ROLE</styles.Category>
            <styles.Description>
              I was the lead product manager, leading a cross functional team of
              15 of designers, software engineers, and data/ machine learning
              engineers. After our first release, I also took up the tech lead
              role. I led product conceptualisation and strategy and led user
              research and design. I was heavily involved with day to day
              development and software and architecture design decisions on top
              of responsibilities such as stakeholder management, feature
              prioritisation, go to market strategy, and the team’s work and
              collaboration processes.
            </styles.Description>
            <styles.Category>SOFTWARE STACK</styles.Category>
            <styles.CategoryList>
              React, NodeJS, Jenkins, Postgres, Dagster, Python
            </styles.CategoryList>
            <styles.Category>IN A NUTSHELL</styles.Category>
            <styles.Description>
              We built a product that consolidates articles and research papers
              from various verified sources for various departments within
              Temasek for their specialised domains. We provide user driven
              tagging of articles and customised recommendations and a dynamic
              smart search feature for them to find articles of interest.
              Between these main features, we facilitate conversations through
              social features, streamline the sharing of articles within teams,
              and provide them with a repository to keep important articles
              using features like shared bookmark folders, historical reads, and
              personal annotations.
            </styles.Description>
          </div>
          <div>
            <styles.Category>SKILLS UTILISED</styles.Category>
            <styles.CategoryList>
              Stakeholder management
              <br />
              User research
              <br />
              UI and interaction design
              <br />
              Feature prioritisation
              <br />
              Change management
              <br />
              Full stack development
              <br />
              Architechture design
              <br />
              Data engineering
            </styles.CategoryList>
            <styles.Category>COMPANY</styles.Category>
            <styles.CategoryList>Temasek International</styles.CategoryList>
          </div>
        </styles.DescriptionWrapper>
        <styles.ApproachWrapper>
          <styles.ApproachHeader>
            Oops, that's all you can see.
          </styles.ApproachHeader>
          <styles.GenericText>
            The rest of the info on the product is sensitive as it is an
            internal product. To view the rest of the project page, please
            <styles.ContactEmail
              data-replace="lirentang93@gmail.com"
              onClick={(e) => {
                window.location.href = "mailto:lirentang93@gmail.com";
                e.preventDefault();
              }}
            >
              <span>feel free to contact me.</span>
            </styles.ContactEmail>
          </styles.GenericText>
        </styles.ApproachWrapper>
      </styles.RegularSection>
      <NextProductSection
        name="Winnow"
        themeColor={`radial-gradient(
          at bottom left,
          ${stripeTheme.orange[90]},
          ${stripeTheme.orange[80]},
          ${stripeTheme.red[50]}
        )`}
        hoverColor={`radial-gradient(
          at bottom left,
          ${stripeTheme.red[90]},
          ${stripeTheme.red[80]},
          ${stripeTheme.orange[50]}
        )`}
        route="/winnow"
      />
    </Background>
  );
};
