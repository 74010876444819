import React, { useState } from "react";
import { useNavigate } from "react-router";
import styled from "styled-components";
import { motion } from "framer-motion";
import { useOnboardingContext } from "../../contexts/OnboardingContext";
import { typeScale, stripeTheme } from "../../themes";

const Background = styled.div`
  height: 100vh;
  position: absolute;
  overflow: hidden;
  display: grid;
  place-items: center;
  width: 100vw;
  transition: 500ms ease-in-out;

  @keyframes background-pan {
    from {
      background-position: 0% center;
    }

    to {
      background-position: -200% center;
    }
  }
`;

const LeftBackground = styled(Background)<{ leftWidth: string }>`
  background: ${stripeTheme.grey[20]};
  color: ${stripeTheme.grey[100]};
  z-index: 3;
  width: ${(props) => props.leftWidth};
`;

const CenterBackground = styled(Background)<{ centerWidth: string }>`
  background: ${stripeTheme.grey[40]};
  color: ${stripeTheme.grey[100]};
  z-index: 2;
  width: ${(props) => props.centerWidth};
`;

const RightBackground = styled(Background)`
  background: ${stripeTheme.grey[100]};
  color: ${stripeTheme.grey[20]};
  z-index: 1;
`;

const Wrapper = styled.div`
  width: 70vw;
  margin: 0 15vw;
`;

const Name = styled.h1`
  display: inline-flex;
  font-size: ${typeScale(3)};
  margin: 0 0 24px 0;
  padding: 0;
  font-weight: bold;
  letter-spacing: 0.2em;
  color: ${stripeTheme.grey[100]};
  text-transform: uppercase;
  cursor: pointer;
  text-align: left;
`;

const ColoredName = styled(Name)`
  animation: background-pan 3s linear infinite;
  background: linear-gradient(
    to right,
    ${stripeTheme.purple[30]},
    ${stripeTheme.violet[30]},
    ${stripeTheme.yellow[30]},
    ${stripeTheme.orange[30]},
    ${stripeTheme.green[30]},
    ${stripeTheme.cyan[30]},
    ${stripeTheme.purple[30]}
  );
  background-size: 200%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  white-space: nowrap;

  :hover {
    background: linear-gradient(
      to right,
      ${stripeTheme.purple[80]},
      ${stripeTheme.violet[80]},
      ${stripeTheme.yellow[80]},
      ${stripeTheme.orange[80]},
      ${stripeTheme.green[80]},
      ${stripeTheme.cyan[80]},
      ${stripeTheme.purple[80]}
    );
    background-size: 200%;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`;

const Introduction = styled.p`
  font-size: ${typeScale(5)};
  font-weight: 200;
  line-height: 1.3;
  margin: 0;
  padding: 0;
  @media screen and (max-width: 1825px) {
    font-size: ${typeScale(5)};
  }
  @media screen and (max-width: 1530px) {
    font-size: ${typeScale(4)};
  }
  @media screen and (max-width: 1200px) {
    font-size: ${typeScale(3)};
  }
  @media screen and (max-width: 770px) {
    font-size: ${typeScale(2)};
  }
  @media screen and (max-width: 550px) {
    font-size: ${typeScale(0)};
  }
  @media screen and (max-width: 450px) {
    font-size: ${typeScale(-1)};
  }
`;

const TransparentIntroduction = styled(Introduction)`
  color: transparent;
`;

const Experience = styled.span`
  cursor: pointer;
  animation: background-pan 3s linear infinite;
  background: linear-gradient(
    to right,
    ${stripeTheme.purple[30]},
    ${stripeTheme.violet[30]},
    ${stripeTheme.yellow[30]},
    ${stripeTheme.orange[30]},
    ${stripeTheme.green[30]},
    ${stripeTheme.cyan[30]},
    ${stripeTheme.purple[30]}
  );
  background-size: 200%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  white-space: nowrap;

  :hover {
    background: linear-gradient(
      to right,
      ${stripeTheme.purple[80]},
      ${stripeTheme.violet[80]},
      ${stripeTheme.yellow[80]},
      ${stripeTheme.orange[80]},
      ${stripeTheme.green[80]},
      ${stripeTheme.cyan[80]},
      ${stripeTheme.purple[80]}
    );
    background-size: 200%;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`;

const Statement = styled.span`
  display: inline-block;
  position: relative;
  background-color: ${stripeTheme.grey[100]};
  cursor: text;
  mix-blend-mode: lighten;
  ::before {
    opacity: 1;
    content: "";
    display: inline-block;
    width: calc(100% - 1px);
    height: 100%;
    position: absolute;
    mix-blend-mode: multiply;
  }
`;

const TransparentStatement = styled(Statement)`
  color: transparent;
`;

const Statement1 = styled(Statement)`
  ::before {
    background-image: linear-gradient(
      147deg,
      ${stripeTheme.yellow[60]} 0%,
      ${stripeTheme.orange[60]} 74%
    );
  }
`;

const Statement2 = styled(Statement)`
  ::before {
    background-image: linear-gradient(
      64deg,
      ${stripeTheme.cyan[60]} 0%,
      ${stripeTheme.green[60]} 100%
    );
  }
`;

const Statement3 = styled(Statement)`
  ::before {
    background-image: linear-gradient(
      225deg,
      ${stripeTheme.violet[60]} 0%,
      ${stripeTheme.purple[60]} 50%,
      ${stripeTheme.blue[60]} 100%
    );
  }
`;

const Arrow = styled.div`
  margin-left: 8px;
`;

export const LandingPage = () => {
  const { onboardingStage, setOnboardingStage } = useOnboardingContext();
  const [leftWidth, setLeftWidth] = useState(
    onboardingStage === 1 ? "100%" : "0%"
  );
  const [centerWidth, setCenterWidth] = useState(
    onboardingStage !== 3 ? "100%" : "0%"
  );
  const navigate = useNavigate();

  return (
    <>
      <LeftBackground leftWidth={leftWidth}>
        <Wrapper>
          <motion.div
            initial={{ y: 50, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 1 }}
          >
            <Name
              onClick={() => {
                setLeftWidth("0%");
                setOnboardingStage(2);
              }}
            >
              Tang Li Ren
              <motion.div
                initial={{ x: -15, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ duration: 1, delay: 1 }}
              >
                <Arrow>→</Arrow>
              </motion.div>
            </Name>
          </motion.div>
          <TransparentIntroduction>
            I’m a technical product manager in Singapore with experience in all
            parts of the product life cycle.
            <br />
            <TransparentStatement>
              I love working with people, systems and code, to
            </TransparentStatement>
            <br />
            <TransparentStatement>
              produce the best user-centered experiences, and
            </TransparentStatement>
            <br />
            <TransparentStatement>
              solve the puzzles of our complex world.
            </TransparentStatement>
          </TransparentIntroduction>
        </Wrapper>
      </LeftBackground>
      <CenterBackground centerWidth={centerWidth}>
        <Wrapper>
          <Name
            onClick={() => {
              setCenterWidth("0%");
              setOnboardingStage(3);
            }}
          >
            Tang Li Ren<Arrow>→</Arrow>
          </Name>
          <Introduction>
            I’m a technical product manager in Singapore with experience in all
            parts of the product life cycle.
            <br />
            <TransparentStatement>
              I love working with people, pixels and code, to
            </TransparentStatement>
            <br />
            <TransparentStatement>
              produce the best user-centered experiences, and
            </TransparentStatement>
            <br />
            <TransparentStatement>
              solve the puzzles of our complex systems.
            </TransparentStatement>
          </Introduction>
        </Wrapper>
      </CenterBackground>
      <RightBackground>
        <Wrapper>
          <ColoredName onClick={() => navigate("/about")}>
            Tang Li Ren
          </ColoredName>
          <Introduction>
            I’m a technical product manager in Singapore with{" "}
            <Experience onClick={() => navigate("/work")}>
              experience
            </Experience>{" "}
            in all parts of the product life cycle.
            <br />
            <Statement1>I love working with people, pixels and code</Statement1>
            , to
            <br />
            <Statement2>produce the best user-centered experiences</Statement2>,
            and
            <br />
            <Statement3>solve the puzzles of our complex systems</Statement3>.
          </Introduction>
        </Wrapper>
      </RightBackground>
    </>
  );
};
