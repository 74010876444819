import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { stripeTheme, typeScale } from "../../../themes";
import { useIsInViewport } from "../../../utilities";

export const NavBar = styled.div<{ refLocation: number }>`
  position: fixed;
  left: 50%;
  transform: translate(-50%, 0);
  bottom: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  border-radius: 16px;
  background: white;
  width: ${(props) => `${props.refLocation * 2 * 33 + 33}%`};
  max-width: 66%;
  padding: 0 16px;
  z-index: 2;

  @media only screen and (max-width: 768px) {
    max-width: 80%;
  }

  @media only screen and (max-width: 480px) {
    max-width: calc(100vw - 32px);
  }
`;

export const CompanyLogo = styled.img<{ isSmall: boolean }>`
  height: ${(props) => (props.isSmall ? "20px " : "40px")};

  @media only screen and (max-width: 480px) {
    height: ${(props) => (props.isSmall ? "10px " : "20px")};
  }
`;

export const CompanyButton = styled.button<{ refLocation: number }>`
  position: absolute;
  background: none;
  border: none;
  cursor: pointer;
  color: ${stripeTheme.grey[100]};
  opacity: ${(props) =>
    props.refLocation < 0.6 ? 1 - props.refLocation * 8 : 0};
  transform: ${(props) => `translateY(${props.refLocation * 100}%)`};
  display: flex;
  align-items: center;
`;

export const NavButton = styled.button.attrs<{
  refLocation: number;
  isCurrentSection: boolean;
}>((props) => ({
  style: {
    isCurrentSection: props.isCurrentSection,
    opacity: props.refLocation > 0.6 ? (props.refLocation - 0.6) * 2.25 : 0,
    transform: `translateY(${props.refLocation * 100 - 100}%)`,
    pointerEvents: props.refLocation < 0.6 ? "none" : "auto",
  },
}))<{ refLocation: number; isCurrentSection: boolean }>`
  font-family: inherit;
  padding: 24px 0;
  margin: 0 8px;
  font-size: ${typeScale(1)};
  font-weight: 600;
  background: none;
  border: none;
  cursor: pointer;
  color: ${(props) =>
    props.isCurrentSection ? stripeTheme.grey[100] : stripeTheme.grey[50]};
  transition: color 200ms ease-in-out;
  position: relative;

  ::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: ${(props) => (props.isCurrentSection ? "3px" : "0")};
    background: ${stripeTheme.grey[100]};
  }

  :hover {
    color: ${stripeTheme.grey[100]};
  }

  @media only screen and (max-width: 768px) {
    font-size: ${typeScale(0)};
  }

  @media only screen and (max-width: 480px) {
    font-size: ${typeScale(-3)};
    padding: 18px 0;
  }
`;

type NavBarProps = {
  refs: {
    hiddenRef: React.RefObject<HTMLDivElement>;
    overviewRef?: React.RefObject<HTMLElement>;
    storyRef?: React.RefObject<HTMLElement>;
    approachRef?: React.RefObject<HTMLElement>;
    howItWorksRef?: React.RefObject<HTMLElement>;
    showcaseRef?: React.RefObject<HTMLElement>;
    resultsRef?: React.RefObject<HTMLElement>;
    reflectionsRef?: React.RefObject<HTMLElement>;
  };
  companyLogo: string;
  refLocation: number;
  logoSize?: "small" | "normal";
};

export const ProductsNavBar = ({
  refs,
  companyLogo,
  refLocation,
  logoSize = "normal",
}: NavBarProps) => {
  const [currentSection, setCurrentSection] = useState("Overview");

  const scrollTo = (ref: React.RefObject<HTMLElement>) => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  };

  const isOverviewInViewport = useIsInViewport(refs.overviewRef);
  const isStoryInViewport = useIsInViewport(refs.storyRef);
  const isApproachInViewport = useIsInViewport(refs.approachRef);
  const isHowItWorksInViewport = useIsInViewport(refs.howItWorksRef);
  const isShowcaseInViewport = useIsInViewport(refs.showcaseRef);
  const isResultsInViewport = useIsInViewport(refs.resultsRef);
  const isReflectionsInViewport = useIsInViewport(refs.reflectionsRef);

  useEffect(() => {
    if (isOverviewInViewport) {
      setCurrentSection("Overview");
    }
    if (isStoryInViewport) {
      setCurrentSection("Story");
    }
    if (isApproachInViewport) {
      setCurrentSection("Approach");
    }
    if (isHowItWorksInViewport) {
      setCurrentSection("How it works");
    }
    if (isShowcaseInViewport) {
      setCurrentSection("Showcase");
    }
    if (isResultsInViewport) {
      setCurrentSection("Results");
    }
    if (isReflectionsInViewport) {
      setCurrentSection("Reflections");
    }
  }, [
    isOverviewInViewport,
    isStoryInViewport,
    isApproachInViewport,
    isHowItWorksInViewport,
    isShowcaseInViewport,
    isResultsInViewport,
    isReflectionsInViewport,
  ]);

  return (
    <NavBar refLocation={refLocation}>
      <CompanyButton
        onClick={() => scrollTo(refs.hiddenRef)}
        refLocation={refLocation}
      >
        <CompanyLogo src={companyLogo} isSmall={logoSize === "small"} />
      </CompanyButton>
      {refs.overviewRef && (
        <NavButton
          onClick={() => scrollTo(refs.hiddenRef)}
          isCurrentSection={currentSection === "Overview"}
          refLocation={refLocation}
        >
          Overview
        </NavButton>
      )}
      {refs.storyRef && (
        <NavButton
          onClick={() => scrollTo(refs.hiddenRef)}
          isCurrentSection={currentSection === "Story"}
          refLocation={refLocation}
        >
          Story
        </NavButton>
      )}
      {refs.approachRef && (
        <NavButton
          onClick={() =>
            scrollTo(refs.approachRef as React.RefObject<HTMLElement>)
          }
          isCurrentSection={currentSection === "Approach"}
          refLocation={refLocation}
        >
          Approach
        </NavButton>
      )}
      {refs.howItWorksRef && (
        <NavButton
          onClick={() =>
            scrollTo(refs.howItWorksRef as React.RefObject<HTMLElement>)
          }
          isCurrentSection={currentSection === "How it works"}
          refLocation={refLocation}
        >
          How it works
        </NavButton>
      )}
      {refs.showcaseRef && (
        <NavButton
          onClick={() =>
            scrollTo(refs.showcaseRef as React.RefObject<HTMLElement>)
          }
          isCurrentSection={currentSection === "Showcase"}
          refLocation={refLocation}
        >
          Showcase
        </NavButton>
      )}
      {refs.resultsRef && (
        <NavButton
          onClick={() =>
            scrollTo(refs.resultsRef as React.RefObject<HTMLElement>)
          }
          isCurrentSection={currentSection === "Results"}
          refLocation={refLocation}
        >
          Results
        </NavButton>
      )}
      {refs.reflectionsRef && (
        <NavButton
          onClick={() =>
            scrollTo(refs.reflectionsRef as React.RefObject<HTMLElement>)
          }
          isCurrentSection={currentSection === "Reflections"}
          refLocation={refLocation}
        >
          Reflections
        </NavButton>
      )}
    </NavBar>
  );
};
