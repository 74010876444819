import React, { useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import { stripeTheme, typeScale } from "../../themes";
import { useOnboardingContext } from "../../contexts/OnboardingContext";

const Container = styled(motion.div)`
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
`;

const Wrapper = styled.div<{ isProjectPage: boolean }>`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: ${(props) =>
    props.isProjectPage ? "space-between" : "end"};
  padding: 36px 32px;
  align-items: center;
  width: 100%;

  @media only screen and (max-width: 768px) {
    padding: 32px 24px;
  }

  @media only screen and (max-width: 480px) {
    padding: 24px 20px;
  }
`;

const RightWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Square = styled.div<{ isOpen: boolean; isAlternateColor: boolean }>`
  background: ${(props) =>
    !props.isOpen
      ? stripeTheme.violet[70]
      : props.isAlternateColor
      ? stripeTheme.grey[20]
      : stripeTheme.grey[90]};
  border: none;
  border-radius: 4px;
  height: 24px;
  width: 24px;
  transition: 200ms ease-in-out;
  cursor: pointer;

  &:hover {
    transform: rotate(45deg);
  }

  @media only screen and (max-width: 768px) {
    height: 20px;
    width: 20px;
  }

  @media only screen and (max-width: 480px) {
    height: 16px;
    width: 16px;
  }
`;
const MotionWrapper = styled(motion.div)`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const NavButton = styled(Link)<{ isAlternateColor: boolean }>`
  overflow: hidden;
  margin-right: 36px;
  position: relative;
  display: inline-block;
  text-decoration: none;
  color: ${(props) =>
    props.isAlternateColor ? stripeTheme.grey[20] : stripeTheme.grey[90]};
  font-size: ${typeScale(0)};

  @media only screen and (max-width: 768px) {
    font-size: ${typeScale(-1)};
  }

  @media only screen and (max-width: 480px) {
    font-size: ${typeScale(-2)};
  }

  ::before,
  ::after {
    content: "";
    position: absolute;
    width: 100%;
    left: 0;
  }

  ::after {
    content: attr(data-replace);
    height: 100%;
    top: 0;
    transform-origin: 100% 50%;
    transform: translate3d(0, 150%, 0);
    transition: transform 0.3s ease-in-out;
    color: ${stripeTheme.grey[60]};
  }

  :hover::after {
    transform: translate3d(0, 0, 0);
  }

  span {
    display: inline-block;
    transition: transform 0.3s ease-in-out;
  }

  :hover span {
    transform: translate3d(0%, -150%, 0);
  }
`;

export const NavBar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const [show, setShow] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);
  const { onboardingStage } = useOnboardingContext();

  const isProjectPage = location.pathname.includes("/work/");
  const isHomePage =
    location.pathname === "/" || location.pathname.includes("hello");
  const showAlternateColor = isHomePage || location.pathname === "/work";

  useEffect(() => {
    const controlNavbar = () => {
      if (typeof window !== "undefined") {
        if (window.scrollY > lastScrollY) {
          setShow(false);
        } else {
          setShow(true);
        }

        setLastScrollY(window.scrollY);
      }
    };

    if (typeof window !== "undefined") {
      window.addEventListener("scroll", controlNavbar);
      return () => {
        window.removeEventListener("scroll", controlNavbar);
      };
    }
  }, [lastScrollY]);

  const toggleNavigation = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      {isHomePage && (onboardingStage === 1 || onboardingStage === 2) ? null : (
        <AnimatePresence>
          {(!isProjectPage || show) && (
            <Container
              initial={{ y: -50, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              exit={{ y: -50, opacity: 0 }}
              transition={{ ease: "linear", duration: 0.3 }}
            >
              <Wrapper isProjectPage={isProjectPage}>
                <AnimatePresence>
                  {isProjectPage && (
                    <MotionWrapper
                      initial={{ y: -50, opacity: 0 }}
                      animate={{ y: 0, opacity: 1 }}
                      transition={{ ease: "linear", duration: 0.3 }}
                    >
                      <NavButton
                        to="/work"
                        data-replace="← All projects"
                        isAlternateColor={false}
                      >
                        <span>← All projects</span>
                      </NavButton>
                    </MotionWrapper>
                  )}
                </AnimatePresence>
                <RightWrapper>
                  <AnimatePresence>
                    {isOpen && (
                      <MotionWrapper
                        initial={{ x: 44, opacity: 0 }}
                        animate={{ x: 0, opacity: 1 }}
                        exit={{ x: 44, opacity: 0 }}
                        transition={{ ease: "linear", duration: 0.3 }}
                      >
                        <NavButton
                          to="/"
                          data-replace="Home"
                          isAlternateColor={showAlternateColor}
                          onClick={toggleNavigation}
                        >
                          <span>Home</span>
                        </NavButton>
                        <NavButton
                          to="/work"
                          data-replace="Work"
                          isAlternateColor={showAlternateColor}
                          onClick={toggleNavigation}
                        >
                          <span>Work</span>
                        </NavButton>
                        <NavButton
                          to="/about"
                          data-replace="About me"
                          isAlternateColor={showAlternateColor}
                          onClick={toggleNavigation}
                        >
                          <span>About me</span>
                        </NavButton>
                      </MotionWrapper>
                    )}
                  </AnimatePresence>
                  <Square
                    onClick={toggleNavigation}
                    isOpen={isOpen}
                    isAlternateColor={showAlternateColor}
                  />
                </RightWrapper>
              </Wrapper>
            </Container>
          )}
        </AnimatePresence>
      )}
    </>
  );
};
