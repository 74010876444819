import { motion } from "framer-motion";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { stripeTheme, typeScale } from "../../themes";
import { ImageCarousell } from "../Products/SharedComponents/ImageCarousell";
import alumniSutd from "../../assets/alumni_sutd.webp";
import digitalForGood from "../../assets/digital_for_good.webp";
import ntuLecture from "../../assets/ntu_lecture.webp";
import sutdTalk from "../../assets/sutd_talk.webp";
import sutdWeb from "../../assets/sutd_web_frameworks.webp";
import tTouchTeaching from "../../assets/t_touch_teaching.webp";

const Content = styled(motion.div)`
  margin-bottom: 120px;

  @media only screen and (max-width: 768px) {
    margin-bottom: 60px;
  }

  @media only screen and (max-width: 480px) {
    margin-bottom: 40px;
  }
`;

const Header = styled.h1`
  font-size: ${typeScale(4)};

  @media only screen and (max-width: 768px) {
    font-size: ${typeScale(2)};
  }

  @media only screen and (max-width: 480px) {
    font-size: ${typeScale(0)};
  }
`;

const Container = styled.div``;

const initialAnimation = { y: 50, opacity: 0 };
const postAnimation = {
  y: 0,
  opacity: 1,
  transition: {
    duration: 0.6,
  },
};
const viewPortOptions = { once: true, amount: 0.4 };

const imageList = [
  {
    img: digitalForGood,
    desc: "Teaching scratch to lesser fortunate primary school kids. I founded the Digital4Good initiative in GovTech and conducted a 10 lesson curriculum across 10 weeks.",
  },
  {
    img: ntuLecture,
    desc: "Conducting a guest lecture in NTU for one of their Design modules. My lecture was about collaborating within a product team, and the basics of how to bring a product to market.",
  },
  {
    img: alumniSutd,
    desc: "I was the founding member and Vice President of the Singapore University of Technology and Design Alumni Association. This was a picture of the working team during our first townhall in a hybrid format where we conducted workshops to identify the wants of our members.",
  },
  {
    img: sutdTalk,
    desc: "Organised and conducted a series of talks for Singapore University of Technology and Design. This is an annual series that I organise to get students up to scratch on what they need to know in the industry. My topic this round was on choosing tech stacks and making products production ready.",
  },
  {
    img: sutdWeb,
    desc: "Another talk in SUTD given to an audience of ~100 freshmen. The talk focused on the basics and fundenmentals of web development and web frameworks.",
  },
  {
    img: tTouchTeaching,
    desc: "Partnering with Singapore Cancer Society, I started an annual initiative within Temasek to teach and expose their beneficiaries to coding and other technologies. This is one of the beneficiaries presenting the game they made in scratch.",
  },
];

export const OthersSection = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const endevoursRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const hash = window.location.hash;
    if (hash === "#endevours") {
      endevoursRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  return (
    <Content
      initial={initialAnimation}
      whileInView={postAnimation}
      viewport={viewPortOptions}
      ref={endevoursRef}
    >
      <Header>A few of my other endevours</Header>
      <Container>
        <ImageCarousell
          images={imageList}
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          color={stripeTheme.blue[90]}
          isAlternative={true}
        />
      </Container>
    </Content>
  );
};
