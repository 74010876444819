import { motion } from "framer-motion";
import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { typeScale } from "../../themes";

const ValuesContent = styled(motion.div)`
  margin-bottom: 120px;

  @media only screen and (max-width: 768px) {
    margin-bottom: 60px;
  }

  @media only screen and (max-width: 480px) {
    margin-bottom: 40px;
  }
`;

const Header = styled.h1`
  font-size: ${typeScale(4)};

  @media only screen and (max-width: 768px) {
    font-size: ${typeScale(2)};
  }

  @media only screen and (max-width: 480px) {
    font-size: ${typeScale(0)};
  }
`;

const ValuesContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 48px;

  @media only screen and (max-width: 480px) {
    grid-template-columns: 1fr;
    grid-gap: 24px;
  }
`;

const ValueWrapper = styled.div`
  margin: 24px 0;
  font-size: ${typeScale(1)};

  @media only screen and (max-width: 768px) {
    font-size: ${typeScale(-1)};
    margin: 0;
  }

  @media only screen and (max-width: 480px) {
    font-size: ${typeScale(-2)};
  }
`;

const ValueTitle = styled.h2`
  font-size: ${typeScale(1)};

  @media only screen and (max-width: 768px) {
    font-size: ${typeScale(-1)};
  }

  @media only screen and (max-width: 480px) {
    font-size: ${typeScale(-2)};
  }
`;

const initialAnimation = { y: 50, opacity: 0 };
const postAnimation = {
  y: 0,
  opacity: 1,
  transition: {
    duration: 0.6,
  },
};
const viewPortOptions = { once: true, amount: 0.4 };

export const ValuesSection = () => {
  const valuesRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const hash = window.location.hash;
    if (hash === "#values") {
      valuesRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  return (
    <ValuesContent
      initial={initialAnimation}
      whileInView={postAnimation}
      viewport={viewPortOptions}
      ref={valuesRef}
    >
      <Header>My Values</Header>
      <ValuesContainer>
        <ValueWrapper>
          <ValueTitle>🦾 Tech as an equalizer</ValueTitle>
          Technology can be used to push boundaries fast or to provide others
          with the means to catch up. My philosophy around technology lies in
          the latter, and I believe that most of the world's problems can be
          solved without the sexiest new technology.
        </ValueWrapper>
        <ValueWrapper>
          <ValueTitle>{`🎯 agile > Agile`}</ValueTitle>
          The point of being agile is not to follow a framework or some rules.
          It's to understand why certain processes and decisions are there, and
          to form smaller feedback loops. I value understanding the fundementals
          of why decisions and processes are there allowing us to utilise raw
          forms of the best practices when the situation calls for it.
        </ValueWrapper>
        <ValueWrapper>
          <ValueTitle>🙌🏻 No individual owns the product</ValueTitle>I believe in
          democratizing product decisions and being transparent to those who
          have the most information about the product, i.e. the team. No
          singular individual's opinion is obselete regardless of domain
          expertise, and it is up to strong rigourous processes that allows the
          team to make the best decision given the circumstance.
        </ValueWrapper>
        <ValueWrapper>
          <ValueTitle>❤️‍🩹 Social impact externally drives me</ValueTitle>
          Bringing impact and meaning to the work I do in both scale and depth
          is important to me. I also prioritise communicating impact of the work
          the team does to them and others, as I believe everyone wants to know
          that they make a difference and are important. Social impact and to
          make inclusive and people centered products and decisions make me the
          happiest.
        </ValueWrapper>
      </ValuesContainer>
    </ValuesContent>
  );
};
