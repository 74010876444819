import { createGlobalStyle } from "styled-components";
import {
  setLightness,
  setSaturation,
  adjustHue,
  transparentize,
} from "polished";

export type typeScaleLevel =
  | -4
  | -3
  | -2
  | -1
  | 0
  | 1
  | 2
  | 3
  | 4
  | 5
  | 6
  | 7
  | 8
  | 9
  | 10
  | 11;
export const typeScale = (level: typeScaleLevel) => {
  if (level < 0) {
    return ["14px", "12px", "10px", "8px"][Math.abs(level) - 1];
  } else {
    return [
      "16px",
      "18px",
      "20px",
      "22px",
      "24px",
      "26px",
      "28px",
      "30px",
      "32px",
      "34px",
      "36px",
      "72px",
    ][level];
  }
};

const palette: { [key in colorName]: {} } = {
  black: {
    "100": "black",
  },
  grey: {
    "5": "#f5f5f5",
    "10": "#d1d1d1",
    "20": "#a3a3a3",
    "30": "#747474",
    "40": "#464646",
    "50": "#181818",
    "60": "#131313",
    "70": "#0e0e0e",
    "80": "#0a0a0a",
    "90": "#050505",
    "100": "#020202",
  },
  primary: {
    "5": "#f4f1ff",
    "10": "#eae3ff",
    "20": "#cfc4ff",
    "30": "#b3a3fb",
    "40": "#9682f1",
    "50": "#7a65e4",
    "60": "#644dcf",
    "70": "#523bb7",
    "80": "#442e9d",
    "90": "#392584",
    "100": "#2f1e6b",
  },
  green: {
    "10": "#80e0db",
    "20": "#6bdbd5",
    "30": "#56d6cf",
    "40": "#41d1c9",
    "50": "#2cccc3",
    "60": "#28b8b0",
    "70": "#23a39c",
    "80": "#1f8f89",
    "90": "#1a7a75",
    "100": "#166662",
  },
  purple: {
    "10": "#9a7ddc",
    "20": "#8967d6",
    "30": "#7851d0",
    "40": "#673cca",
    "50": "#5626c4",
    "60": "#4d22b0",
    "70": "#451e9d",
    "80": "#3c1b89",
    "90": "#341776",
    "100": "#2b1362",
  },
  pink: {
    "10": "#f069ad",
    "20": "#ee509f",
    "30": "#eb3791",
    "40": "#e91e84",
    "50": "#e60576",
    "60": "#cf056a",
    "70": "#b8045e",
    "80": "#a10453",
    "90": "#8a0347",
    "100": "#73033b",
  },
  yellow: {
    "10": "#fce18b",
    "20": "#fcdc77z",
    "30": "#fbd764",
    "40": "#fbd250",
    "50": "#facd3d",
    "60": "#e1b937",
    "70": "#c8a431",
    "80": "#af902b",
    "90": "#967b25",
    "100": "#7d671f",
  },
  orange: {
    "10": "#ffaf97",
    "20": "#ffa285",
    "30": "#ff9574",
    "40": "#ff8762",
    "50": "#ff7a51",
    "60": "#e66e49",
    "70": "#cc6241",
    "80": "#b35539",
    "90": "#994931",
    "100": "#663120",
  },
};

export type colorName =
  | "black"
  | "grey"
  | "primary"
  | "green"
  | "purple"
  | "pink"
  | "yellow"
  | "orange";

export type toneLevel =
  | 5
  | 10
  | 15
  | 20
  | 30
  | 40
  | 50
  | 60
  | 70
  | 80
  | 90
  | 95
  | 100;

export const colorScale =
  (name: colorName, tone: toneLevel, modifier?: (color: string) => string) =>
  (props: ITheme) => {
    if (props.theme.palette.hasOwnProperty(name)) {
      if (props.theme.palette[name].hasOwnProperty(tone)) {
        if (modifier) {
          return modifier(props.theme.palette[name][tone]);
        } else {
          return props.theme.palette[name][tone];
        }
      } else {
        throw new Error(`${tone} is not a valid color grade for ${name}`);
      }
    } else {
      throw new Error(`${name} does not exist in the color palette`);
    }
  };

export type gradientName = "blue-purple";

const gradients: {
  [key in gradientName]: {
    start: (theme: IThemeObject) => string;
    end: (theme: IThemeObject) => string;
  };
} = {
  "blue-purple": {
    start: (theme) =>
      colorScale("primary", 60, (c) =>
        setLightness(0.6, setSaturation(0.55, adjustHue(360 - 10, c)))
      )({ theme }),
    end: (theme) =>
      colorScale("primary", 60, (c) =>
        setLightness(0.6, setSaturation(0.55, adjustHue(10, c)))
      )({
        theme,
      }),
  },
};

export const gradient =
  (name: gradientName, angle: string) => (props: ITheme) => {
    const start = gradients[name].start(props.theme);
    const end = gradients[name].end(props.theme);
    return `linear-gradient(${angle}, ${start} 0%, ${end} 100%)`;
  };

export const stripeTheme = {
  grey: {
    100: "#32335A",
    90: "#43486E",
    80: "#545F7C",
    70: "#6E7B91",
    60: "#8B97A8",
    50: "#ADB6C3",
    40: "#D0D6DE",
    30: "#E8EAF0",
    20: "#F6F9FC",
  },
  blue: {
    100: "#32335A",
    90: "#434787",
    80: "#565CB9",
    70: "#6874DD",
    60: "#7C96F1",
    50: "#92BAF8",
    40: "#A9DBFB",
    30: "#CDEEFD",
    20: "#EFFAFE",
  },
  cyan: {
    100: "#1A4275",
    90: "#285A94",
    80: "#3B7AB2",
    70: "#4F97CE",
    60: "#63B1E3",
    50: "#83D3F5",
    40: "#A5EDFA",
    30: "#C6FCFE",
    20: "#EBFEFF",
  },
  green: {
    100: "#23534F",
    90: "#337162",
    80: "#459473",
    70: "#57B082",
    60: "#6CCB93",
    50: "#91E1A9",
    40: "#BCEEBB",
    30: "#DCF9D2",
    20: "#F5FEF0",
  },
  yellow: {
    100: "#923C28",
    90: "#A95B36",
    80: "#C37F47",
    70: "#D9A159",
    60: "#EDBF69",
    50: "#F5D779",
    40: "#F9E892",
    30: "#FAF3BA",
    20: "#FDFDE7",
  },
  orange: {
    100: "#721C3E",
    90: "#912D47",
    80: "#B3474E",
    70: "#D26257",
    60: "#EA7D61",
    50: "#F2A582",
    40: "#F7CEAA",
    30: "#FBE8CE",
    20: "#FDF7EF",
  },
  red: {
    100: "#822A31",
    90: "#9D433C",
    80: "#BB6349",
    70: "#D58056",
    60: "#EB9E66",
    50: "#F3BE7E",
    40: "#F6DA9C",
    30: "#FBEEC4",
    20: "#FDFAEB",
  },
  purple: {
    100: "#472773",
    90: "#633B8C",
    80: "#8956A6",
    70: "#AC70BF",
    60: "#CB88D5",
    50: "#EAA9E7",
    40: "#F6CAEC",
    30: "#FAE2F3",
    20: "#FDF5FD",
  },
  violet: {
    100: "#3B2E67",
    90: "#534289",
    80: "#6E59B1",
    70: "#8971CE",
    60: "#A28FE3",
    50: "#BCB2F0",
    40: "#CDD2F3",
    30: "#E2E7F8",
    20: "#F6F8FE",
  },
};

export type shadowLevel = 1 | 2 | 3;
export const shadowScale =
  (level: shadowLevel, color = colorScale("grey", 100)) =>
  (props: ITheme) => {
    const shadowColor = color(props);
    switch (level) {
      case 1:
        return `
        0px 0px 2px ${transparentize(0.9, shadowColor)},
        0px 1px 2px ${transparentize(0.85, shadowColor)};
      `;
      case 2:
        return `
        0px 2px 4px ${transparentize(0.88, shadowColor)},
        0px 10px 20px ${transparentize(0.9, shadowColor)};

      `;
      case 3:
        return `
        0px 3px 6px ${transparentize(0.9, shadowColor)},
        0px 16px 48px ${transparentize(0.92, shadowColor)};
      `;
    }
  };

const fonts = {
  mainFontFamily: `'Rubik', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu,
  Cantarell, 'Helvetica Neue', Helvetica, Arial, sans-serif`,
  alternateFontFamily: `'Playfair Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu,
  Cantarell, 'Helvetica Neue', Helvetica, Arial, sans-serif`,
};

const transitions = {
  duration: "0.2s",
  durationLonger: "0.4s",
};

const borders = {
  radius: "4px",
  radiusLarge: "6px",
  radiusRound: "1000px",
  divider: "1px",
};

const heights = {
  navbar: "60px",
  mobileNavbar: "56px",
};

const layers = {
  modal: 100,
  notification: 90,
  dropdown: 80,
  tooltip: 70,
  navbar: 65,
  captiveHeader: 65,
  sticky: 60,
};

export const theme = {
  palette,
  fonts,
  transitions,
  borders,
  heights,
  layers,
};

export interface ITheme {
  theme: {
    palette: {
      [color: string]: {
        [tone: string]: string;
      };
    };
    fonts: {
      [fontType: string]: string;
    };
    transitions: {
      [prop: string]: string;
    };
    borders: {
      [prop: string]: string;
    };
    heights: {
      [prop: string]: string;
    };
    layers: {
      [layer: string]: number;
    };
  };
}

export type IThemeObject = ITheme["theme"];

export const GlobalStyle = createGlobalStyle`
  html {
    box-sizing: border-box;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }

  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }

  body {
    margin: 0;
    padding: 0;
    font-family: ${fonts.mainFontFamily};
    font-size: 100%;
    line-height: 1.2;
    color: #32325D;
    background: ${stripeTheme.grey[30]};

    p, li {
      line-height: 1.4;
    }

    svg {
      transition: inherit;
    }

    & ::-webkit-scrollbar {
      width: 0;
      height: 0;
    }

    & ::-webkit-scrollbar-thumb {
      background: rgba(0, 0, 0, 0.1);
    }

    & ::-webkit-scrollbar-track {
      all: unset;
    }
  }
`;
