import React from "react";
import { useNavigate } from "react-router";
import styled from "styled-components";
import { stripeTheme, typeScale } from "../../../themes";
import * as styles from "../sharedStyles";

const Wrapper = styled.section<{
  themeColor: string;
  hoverColor: string;
}>`
  width: 100%;
  background: ${(props) => props.themeColor};
  padding: 96px 0 180px;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  position: relative;
  z-index: 1;

  ::before {
    position: absolute;
    content: "";
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: ${(props) => props.hoverColor};
    z-index: -1;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }

  :hover {
    ::before {
      opacity: 1;
    }
  }
`;

const NextProductText = styled.div`
  font-size: ${typeScale(-2)};
  color: ${stripeTheme.grey[20]};
`;

const Divider = styled.div`
  width: 24px;
  height: 2px;
  background: ${stripeTheme.grey[20]};
  margin: 24px 0;
`;

type NextProductSectionProps = {
  name: string;
  themeColor: string;
  hoverColor: string;
  route: string;
};

export const NextProductSection = ({
  name,
  themeColor,
  hoverColor,
  route,
}: NextProductSectionProps) => {
  const navigate = useNavigate();

  const routeToPage = () => {
    navigate(`/work${route}`);
  };

  return (
    <Wrapper
      themeColor={themeColor}
      hoverColor={hoverColor}
      onClick={routeToPage}
    >
      <NextProductText>NEXT PRODUCT</NextProductText>
      <Divider />
      <styles.MainTitle
        initial={{ y: 50, opacity: 0 }}
        whileInView={{
          y: 0,
          opacity: 1,
          transition: { duration: 0.5, delay: 0.5 },
        }}
        viewport={{ once: true, amount: 0.5 }}
      >
        {name}
      </styles.MainTitle>
    </Wrapper>
  );
};
