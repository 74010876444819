import React from "react";
import reportWebVitals from "./reportWebVitals";
import { ThemeProvider } from "styled-components";
import { theme, GlobalStyle } from "./themes";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { createRoot } from "react-dom/client";
import { LandingPage } from "./Components/LandingPage/LandingPage";
import { AboutPage } from "./Components/AboutPage/AboutPage";
import { WINS } from "./Components/Products/WINS";
import { MyMoneySense } from "./Components/Products/MyMoneySense";
import { TGini } from "./Components/Products/TGini";
import { ScrollToTop } from "./ScrollToTop";
import { SupplyAlly } from "./Components/Products/SupplyAlly";
import { NavBar } from "./Components/NavBar/NavBar";
import { WorkListPage } from "./Components/WorkPage/WorkListPage";
import { OnboardingProvider } from "./contexts/OnboardingContext";
import { ResumePage } from "./Components/ResumePage/ResumePage";
import { Winnow } from "./Components/Products/Winnow";
import { TalentHub } from "./Components/Products/TalentHub";
import { HelloVisa } from "./Components/LandingPage/HelloVisa";
import { HelloWise } from "./Components/LandingPage/HelloWise";
import { HelloDBS } from "./Components/LandingPage/HelloDBS";
import { NotFoundPage } from "./Components/NotFoundPage/NotFoundPage";
import { HelloGovtech } from "./Components/LandingPage/HelloGovtech";
import { HelloMSF } from "./Components/LandingPage/HelloMSF";

const container = document.getElementById("root");
const root = createRoot(container!);

root.render(
  <ThemeProvider theme={theme}>
    <OnboardingProvider>
      <React.Fragment>
        <GlobalStyle />
        <BrowserRouter>
          <ScrollToTop />
          <NavBar />
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/resume" element={<ResumePage />} />
            <Route path="/work" element={<WorkListPage />} />
            <Route path="/work/wins" element={<WINS />} />
            <Route path="/work/mymoneysense" element={<MyMoneySense />} />
            <Route path="/work/supplyally" element={<SupplyAlly />} />
            <Route path="/work/tgini" element={<TGini />} />
            <Route path="/work/winnow" element={<Winnow />} />
            <Route path="/work/talenthub" element={<TalentHub />} />
            <Route path="/hello/visa" element={<HelloVisa />} />
            <Route path="/hello/wise" element={<HelloWise />} />
            <Route path="/hello/dbs" element={<HelloDBS />} />
            <Route path="/hello/govtech" element={<HelloGovtech />} />
            <Route path="/hello/msf" element={<HelloMSF />} />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </BrowserRouter>
      </React.Fragment>
    </OnboardingProvider>
  </ThemeProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
