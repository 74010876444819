import React, { useRef, useEffect, useState } from "react";
import styled from "styled-components";
import { stripeTheme } from "../../themes";
import * as styles from "./sharedStyles";
import logo from "../../assets/temasek-logo.webp";
import { ProductsNavBar } from "./SharedComponents/ProductsNavBar";
import { useScroll } from "framer-motion";
import { TitleSection } from "./SharedComponents/TitleSection";
import mainImage from "../../assets/projects/talenthub/360feedback_home.webp";
import { ImageCarousell } from "./SharedComponents/ImageCarousell";
import giveFeedback from "../../assets/projects/talenthub/360feedback_give.webp";
import search from "../../assets/projects/talenthub/talentnet_search.webp";
import analytics from "../../assets/projects/talenthub/talentprofile_analytics.webp";

const Background = styled(styles.Background)`
  color: ${stripeTheme.purple[100]};
`;

const imageList1 = [
  {
    img: giveFeedback,
    desc: "360Feedback, a product where users can request and provide feedback anywhere at any time, inculcating a culture of continuous feedback. And making feedback more timely and tangible as it relates specifically to work done at that point of time.",
  },
  {
    img: search,
    desc: "TalentNET, a product allowing users to edit and search for non-executive talents. The search feature allows for specific options and pulls up only relevant data points for easy and fast filtering.",
  },
  {
    img: analytics,
    desc: "TalentProfile, a product for managers to view, analyse and compare their team's feedback and strengths. Allowing managers to come up with quick and timely interventions on feedback and to build their team's strengths and work on development areas.",
  },
];

export const TalentHub = () => {
  const hiddenRef = useRef<HTMLDivElement>(null);
  const overviewRef = useRef<HTMLElement>(null);
  const [refLocation, setRefLocation] = useState(0);
  const [currentStep1, setCurrentStep1] = useState(1);

  const { scrollYProgress } = useScroll({
    target: hiddenRef,
    offset: ["end end", "start start"],
  });

  useEffect(() => {
    if (scrollYProgress.get() !== 0) {
      setRefLocation(scrollYProgress.get());
    }
    scrollYProgress.onChange((v) => {
      setRefLocation(v);
    });
  }, [scrollYProgress]);

  return (
    <Background>
      <ProductsNavBar
        refs={{
          hiddenRef: hiddenRef,
          overviewRef: overviewRef,
        }}
        companyLogo={logo}
        refLocation={refLocation}
        logoSize="small"
      />
      <TitleSection
        themeColor={`radial-gradient(
          at bottom left,
          ${stripeTheme.purple[90]},
          ${stripeTheme.purple[80]},
          ${stripeTheme.violet[50]}
        )`}
        name="TalentHub"
      />
      <styles.RegularSection ref={overviewRef}>
        <styles.HiddenRefDiv ref={hiddenRef} />
        <styles.MainImage src={mainImage} />
        <styles.SubTitle>
          Building a continuous feedback culture
          <br />
          by empowering all stakeholders of the feedback review cycle
        </styles.SubTitle>
        <styles.DescriptionWrapper>
          <div>
            <styles.Description>
              Currently the performance and feedback review cycle is done once a
              year. Leaving individuals dreading spending a bulk of their time
              at the end of the year going through the cycle. Feedback is also
              very biased towards year end projects, and managers have a lack of
              visibility of data to provide useful learning goals and plans for
              their team. How might we empower individuals with the tools and
              means to provide timely, specific and incentivized feedback and
              growth plans?
            </styles.Description>
            <styles.Category>MY ROLE</styles.Category>
            <styles.Description>
              I was the lead product manager in a team of ~25 designers,
              developers, data/ machine learning engineers, business analyists
              and other product managers. I led the team and oversaw 4 linked
              products from conceptualisation and pitching all the way to
              maintence through multiple milestones. I mentored and empowered
              other members through user research and product sense, build
              processes to lower feedback loops, and conducted the usual product
              manager activities, including budgeting, value management, and new
              value creation. As a technical individual at heart I was heavily
              involved in day to day development and UI/UX decisions, and
              software and architecture design decisions.
            </styles.Description>
            <styles.Category>SOFTWARE STACK</styles.Category>
            <styles.CategoryList>
              React, NodeJS, Jenkins, Postgres, Airflow, Python
            </styles.CategoryList>
            <styles.Category>IN A NUTSHELL</styles.Category>
            <styles.Description>
              We built a platform that holds talent data in data buckets and
              made modular services for common functionalities such as access
              control. Through the past ~18 months, we released 4 interconnected
              products on this platform in multiple milestones. 2 products are
              focused on specific non-executive talent use cases such as
              classifying and searching through a database of talents. Another
              product gives users access to an official feedback channel
              throughout the year. Giving users an avenue to provide more timely
              and specific feedback related to projects. The final product
              allows managers to view their team's feedback and ratings, and
              utilises ML/AI to provide analytics and summaries of to
              qualitative feedback. Along with the ability to compare and
              benchmark their team's performance, gives the managers the ability
              and incentive to take growth into their own hands.
            </styles.Description>
          </div>
          <div>
            <styles.Category>SKILLS UTILISED</styles.Category>
            <styles.CategoryList>
              Stakeholder management
              <br />
              User research
              <br />
              UI and interaction design
              <br />
              Feature prioritisation
              <br />
              Change management
              <br />
              Full stack development
              <br />
              Architechture design
              <br />
              Data engineering
            </styles.CategoryList>
            <styles.Category>COMPANY</styles.Category>
            <styles.CategoryList>Temasek International</styles.CategoryList>
          </div>
        </styles.DescriptionWrapper>
      </styles.RegularSection>
      <ImageCarousell
        images={imageList1}
        currentStep={currentStep1}
        setCurrentStep={setCurrentStep1}
        color={stripeTheme.purple[90]}
      />
      <styles.RegularSection>
        <styles.ApproachWrapper>
          <styles.ApproachHeader>
            Oops, that's all you can see.
          </styles.ApproachHeader>
          <styles.GenericText>
            The rest of the info on the product is sensitive as it is an
            internal product. To view the rest of the project page, please
            <styles.ContactEmail
              data-replace="lirentang93@gmail.com"
              onClick={(e) => {
                window.location.href = "mailto:lirentang93@gmail.com";
                e.preventDefault();
              }}
            >
              <span>feel free to contact me.</span>
            </styles.ContactEmail>
          </styles.GenericText>
        </styles.ApproachWrapper>
      </styles.RegularSection>
    </Background>
  );
};
