import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { stripeTheme, typeScale } from "../../themes";

const Container = styled.div`
  margin: 120px 5%;

  @media only screen and (max-width: 768px) {
    margin: 96px 5%;
  }

  @media only screen and (max-width: 480px) {
    margin: 48px 24px;
  }
`;

const Header = styled.h1`
  font-size: 240px;
  color: ${stripeTheme.blue[80]};
  margin: 0;
  @media only screen and (max-width: 768px) {
    font-size: 120px;
  }

  @media only screen and (max-width: 480px) {
    font-size: 96px;
  }
`;

const Text = styled.h2`
  font-size: ${typeScale(7)};
  color: ${stripeTheme.blue[80]};

  @media only screen and (max-width: 768px) {
    font-size: ${typeScale(7)};
  }

  @media only screen and (max-width: 480px) {
    font-size: ${typeScale(5)};
  }
`;

const StyledLink = styled(Link)`
  overflow: hidden;
  position: relative;
  font-weight: bold;
  display: inline-flex;
  text-decoration: none;
  color: inherit;
  font-size: inherit;
  bottom: 0;
  line-height: inherit;
  color: ${stripeTheme.blue[80]};
  font-size: ${typeScale(5)};

  @media only screen and (max-width: 768px) {
    font-size: ${typeScale(5)};
  }

  @media only screen and (max-width: 480px) {
    font-size: ${typeScale(3)};
  }

  ::before,
  ::after {
    content: "";
    position: absolute;
    width: 100%;
    left: 0;
  }

  ::after {
    content: attr(data-replace);
    height: 100%;
    top: 0;
    transform-origin: 100% 50%;
    transform: translate3d(0, 150%, 0);
    transition: transform 0.3s ease-in-out;
    color: ${stripeTheme.blue[60]};
  }

  :hover::after {
    transform: translate3d(0, 0, 0);
  }

  span {
    display: inline-block;
    transition: transform 0.3s ease-in-out;
    color: ${stripeTheme.blue[80]};
  }

  :hover span {
    transform: translate3d(0%, -150%, 0);
  }
`;

export const NotFoundPage = () => {
  return (
    <Container>
      <Header>404</Header>
      <Text>
        Oops... The page you are looking for cannot be found.
        <br />
        Let's get you back on track!
      </Text>
      <StyledLink to="/" data-replace="Back to home!">
        <span>Go back home →</span>
      </StyledLink>
    </Container>
  );
};
