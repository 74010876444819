import React, { useState } from "react";
import { useNavigate } from "react-router";
import styled from "styled-components";
import { motion } from "framer-motion";
import { useOnboardingContext } from "../../contexts/OnboardingContext";
import { typeScale, stripeTheme } from "../../themes";
import { Link } from "react-router-dom";

const Background = styled.div`
  height: 100vh;
  position: absolute;
  overflow: hidden;
  display: grid;
  place-items: center;
  width: 100vw;
  transition: 500ms ease-in-out;

  @keyframes background-pan {
    from {
      background-position: 0% center;
    }

    to {
      background-position: -200% center;
    }
  }
`;

const LeftBackground = styled(Background)<{ leftWidth: string }>`
  background: ${stripeTheme.grey[20]};
  color: ${stripeTheme.grey[100]};
  z-index: 3;
  width: ${(props) => props.leftWidth};
`;

const CenterBackground = styled(Background)<{ centerWidth: string }>`
  background: ${stripeTheme.grey[40]};
  color: ${stripeTheme.grey[100]};
  z-index: 2;
  width: ${(props) => props.centerWidth};
`;

const RightBackground = styled(Background)<{ isDone: boolean }>`
  background: ${stripeTheme.grey[100]};
  color: ${stripeTheme.grey[20]};
  z-index: 1;
  height: ${(props) => (props.isDone ? "auto" : "100vh")};
  padding: 120px 0;
`;

const Wrapper = styled.div`
  width: 70vw;
  margin: 0 15vw;
`;

const Name = styled.h1`
  display: inline-flex;
  font-size: ${typeScale(3)};
  margin: 0 0 24px 0;
  padding: 0;
  font-weight: bold;
  letter-spacing: 0.2em;
  color: ${stripeTheme.grey[100]};
  text-transform: uppercase;
  cursor: pointer;
  text-align: left;
`;

const ColoredName = styled(Name)`
  animation: background-pan 3s linear infinite;
  background: linear-gradient(
    to right,
    ${stripeTheme.purple[30]},
    ${stripeTheme.violet[30]},
    ${stripeTheme.yellow[30]},
    ${stripeTheme.orange[30]},
    ${stripeTheme.green[30]},
    ${stripeTheme.cyan[30]},
    ${stripeTheme.purple[30]}
  );
  background-size: 200%;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  white-space: nowrap;

  :hover {
    background: linear-gradient(
      to right,
      ${stripeTheme.purple[80]},
      ${stripeTheme.violet[80]},
      ${stripeTheme.yellow[80]},
      ${stripeTheme.orange[80]},
      ${stripeTheme.green[80]},
      ${stripeTheme.cyan[80]},
      ${stripeTheme.purple[80]}
    );
    background-size: 200%;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`;

const Introduction = styled.p`
  font-size: ${typeScale(5)};
  font-weight: 200;
  line-height: 1.3;
  margin: 0;
  padding: 0;
  @media screen and (max-width: 1825px) {
    font-size: ${typeScale(5)};
  }
  @media screen and (max-width: 1530px) {
    font-size: ${typeScale(4)};
  }
  @media screen and (max-width: 1200px) {
    font-size: ${typeScale(3)};
  }
  @media screen and (max-width: 770px) {
    font-size: ${typeScale(2)};
  }
  @media screen and (max-width: 550px) {
    font-size: ${typeScale(0)};
  }
  @media screen and (max-width: 450px) {
    font-size: ${typeScale(-1)};
  }
`;

const TransparentIntroduction = styled(Introduction)`
  color: transparent;
`;

const Experience = styled.span`
  cursor: pointer;
  animation: background-pan 3s linear infinite;
  background: linear-gradient(
    to right,
    ${stripeTheme.purple[30]},
    ${stripeTheme.violet[30]},
    ${stripeTheme.yellow[30]},
    ${stripeTheme.orange[30]},
    ${stripeTheme.green[30]},
    ${stripeTheme.cyan[30]},
    ${stripeTheme.purple[30]}
  );
  background-size: 200%;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  white-space: nowrap;

  :hover {
    background: linear-gradient(
      to right,
      ${stripeTheme.purple[80]},
      ${stripeTheme.violet[80]},
      ${stripeTheme.yellow[80]},
      ${stripeTheme.orange[80]},
      ${stripeTheme.green[80]},
      ${stripeTheme.cyan[80]},
      ${stripeTheme.purple[80]}
    );
    background-size: 200%;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`;

const Statement = styled.span`
  display: inline-block;
  position: relative;
  background-color: ${stripeTheme.grey[100]};
  cursor: text;
  mix-blend-mode: lighten;
  ::before {
    opacity: 1;
    content: "";
    display: inline-block;
    width: calc(100% - 1px);
    height: 100%;
    position: absolute;
    mix-blend-mode: multiply;
  }
`;

const TransparentStatement = styled(Statement)`
  color: transparent;
`;

const Statement1 = styled(Statement)`
  ::before {
    background-image: linear-gradient(
      147deg,
      ${stripeTheme.yellow[60]} 0%,
      ${stripeTheme.orange[60]} 74%
    );
  }
`;

const Statement2 = styled(Statement)`
  ::before {
    background-image: linear-gradient(
      64deg,
      ${stripeTheme.cyan[60]} 0%,
      ${stripeTheme.green[60]} 100%
    );
  }
`;

const Statement3 = styled(Statement)`
  ::before {
    background-image: linear-gradient(
      225deg,
      ${stripeTheme.violet[60]} 0%,
      ${stripeTheme.purple[60]} 50%,
      ${stripeTheme.blue[60]} 100%
    );
  }
`;

const Arrow = styled.div`
  margin-left: 8px;
`;

const Description = styled.div`
  font-weight: 300;
  font-size: ${typeScale(3)};
  margin-top: 48px;

  @media screen and (max-width: 1200px) {
    font-size: ${typeScale(2)};
  }
  @media screen and (max-width: 770px) {
    font-size: ${typeScale(1)};
  }
  @media screen and (max-width: 550px) {
    font-size: ${typeScale(0)};
  }
  @media screen and (max-width: 450px) {
    font-size: ${typeScale(-1)};
  }

  > ul {
    padding-left: 14px;
    font-size: inherit;
    > li {
      margin-bottom: 24px;
      font-size: inherit;
    }
  }

  b {
    font-weight: 500;
  }
`;

const StyledLink = styled(Link)`
  overflow: hidden;
  position: relative;
  display: inline-flex;
  text-decoration: none;
  color: inherit;
  font-size: inherit;
  bottom: 0;
  line-height: inherit;

  ::before,
  ::after {
    content: "";
    position: absolute;
    width: 100%;
    left: 0;
  }

  ::after {
    content: attr(data-replace);
    height: 100%;
    top: 0;
    transform-origin: 100% 50%;
    transform: translate3d(0, 150%, 0);
    transition: transform 0.3s ease-in-out;
    color: ${stripeTheme.grey[60]};
  }

  :hover::after {
    transform: translate3d(0, 0, 0);
  }

  span {
    display: inline-block;
    transition: transform 0.3s ease-in-out;
    color: ${stripeTheme.green[60]};
  }

  :hover span {
    transform: translate3d(0%, -150%, 0);
  }
`;

const StyledA = styled.a`
  overflow: hidden;
  position: relative;
  display: inline-flex;
  text-decoration: none;
  color: inherit;
  font-size: inherit;
  cursor: pointer;

  ::before,
  ::after {
    content: "";
    position: absolute;
    width: 100%;
    left: 0;
  }

  ::after {
    content: attr(data-replace);
    height: 100%;
    top: 0;
    transform-origin: 100% 50%;
    transform: translate3d(0, 150%, 0);
    transition: transform 0.3s ease-in-out;
    color: ${stripeTheme.grey[60]};
  }

  :hover::after {
    transform: translate3d(0, 0, 0);
  }

  span {
    display: inline-block;
    transition: transform 0.3s ease-in-out;
    color: ${stripeTheme.red[60]};
  }

  :hover span {
    transform: translate3d(0%, -150%, 0);
  }
`;

export const HelloDBS = () => {
  const { onboardingStage, setOnboardingStage } = useOnboardingContext();
  const [leftWidth, setLeftWidth] = useState(
    onboardingStage === 1 ? "100%" : "0%"
  );
  const [centerWidth, setCenterWidth] = useState(
    onboardingStage !== 3 ? "100%" : "0%"
  );
  const navigate = useNavigate();

  return (
    <>
      <LeftBackground leftWidth={leftWidth}>
        <Wrapper>
          <motion.div
            initial={{ y: 50, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 1 }}
          >
            <Name
              onClick={() => {
                setLeftWidth("0%");
                setOnboardingStage(2);
              }}
            >
              Hello DBS 👋!
              <br />
              I'm Tang Li Ren
              <motion.div
                initial={{ x: -15, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ duration: 1, delay: 1 }}
              >
                <Arrow>→</Arrow>
              </motion.div>
            </Name>
          </motion.div>
          <TransparentIntroduction>
            I’m a technical product manager in Singapore with experience in all
            parts of the product life cycle.
            <br />
            <TransparentStatement>
              I love working with people, systems and code, to
            </TransparentStatement>
            <br />
            <TransparentStatement>
              produce the best user-centered experiences, and
            </TransparentStatement>
            <br />
            <TransparentStatement>
              solve the puzzles of our complex world.
            </TransparentStatement>
          </TransparentIntroduction>
        </Wrapper>
      </LeftBackground>
      <CenterBackground centerWidth={centerWidth}>
        <Wrapper>
          <Name
            onClick={() => {
              setCenterWidth("0%");
              setOnboardingStage(3);
            }}
          >
            Hello DBS 👋!
            <br />
            I'm Tang Li Ren<Arrow>→</Arrow>
          </Name>
          <Introduction>
            I’m a technical product manager in Singapore with experience in all
            parts of the product life cycle.
            <br />
            <TransparentStatement>
              I love working with people, pixels and code, to
            </TransparentStatement>
            <br />
            <TransparentStatement>
              produce the best user-centered experiences, and
            </TransparentStatement>
            <br />
            <TransparentStatement>
              solve the puzzles of our complex systems.
            </TransparentStatement>
          </Introduction>
        </Wrapper>
      </CenterBackground>
      <RightBackground isDone={onboardingStage === 3}>
        <Wrapper>
          <ColoredName onClick={() => navigate("/about")}>
            Hello DBS 👋!
            <br />
            I'm Tang Li Ren
          </ColoredName>
          <Introduction>
            I’m a technical product manager in Singapore with{" "}
            <Experience onClick={() => navigate("/work")}>
              experience
            </Experience>{" "}
            in all parts of the product life cycle.
            <br />
            <Statement1>I love working with people, pixels and code</Statement1>
            , to
            <br />
            <Statement2>produce the best user-centered experiences</Statement2>,
            and
            <br />
            <Statement3>solve the puzzles of our complex systems</Statement3>.
          </Introduction>
          <Description>
            I think I am a great fit for your company because:
            <ul>
              <li>
                I really resonate with your goal of{" "}
                <b>making positive impact beyond just banking</b> and your drive
                for <b>agility and being purpose and technology driven</b>.
                Democratising the innovative solutions for businesses and
                individuals regardless of their background. Check out{" "}
                <StyledLink to="/about#values" data-replace="my values">
                  <span>my values</span>
                </StyledLink>{" "}
                I strive towards daily to see if we align.
              </li>
              <li>
                I have my fair share of{" "}
                <b>experience in the financial sector</b> given my experience:
                <ul>
                  <li>
                    Conceptualising and devilering{" "}
                    <StyledLink
                      to="/work/mymoneysense"
                      data-replace="MyMoneySense"
                    >
                      <span>MyMoneySense</span>
                    </StyledLink>{" "}
                    an online tool making financial planning available and
                    understandable for everyone.
                    <li>
                      Being part of the inception and building of{" "}
                      <StyledA
                        href="https://www.singpass.gov.sg/main/sgfindex/"
                        target="_blank"
                        data-replace="SGFinDex"
                      >
                        <span>SGFinDex</span>
                      </StyledA>
                      , the world's first public digital infrastructure that
                      enables individuals to access financial information held
                      across government agencies and financial institutions.
                    </li>
                    <li>
                      I am also a lead product manager across multiple products
                      in Temasek International{" "}
                      <b>directly working with investment teams</b> to build
                      meaningful and exciting bespoke products such as{" "}
                      <StyledLink to="/work/winnow" data-replace="Winnow">
                        <span>Winnow</span>
                      </StyledLink>{" "}
                      that consolidates and stiches together company data on a{" "}
                      <b>single pane of glass from varied data sources</b>,
                      using AI/ML to analyse and bring forth trends about the
                      company and its competitors.
                    </li>
                    <li>
                      In Temasek I also led{" "}
                      <StyledLink to="/work/tgini" data-replace="T-Gini">
                        <span>T-Gini</span>
                      </StyledLink>{" "}
                      utilising AI/ML to{" "}
                      <b>
                        improve discoverability of relevant news and research
                      </b>{" "}
                      of companies.
                    </li>
                    <li>
                      As a bonus I was also the{" "}
                      <b>feature lead for a payments revamp</b> for{" "}
                      <StyledLink
                        to="/work/wins"
                        data-replace="Workpass Integration System (WINS)"
                      >
                        <span>Workpass Integration System (WINS)</span>
                      </StyledLink>
                      , facilitating{" "}
                      <b>more than 150,000 transactions per year</b>.
                    </li>
                  </li>
                </ul>
              </li>
              <li>
                I have worked in all stages of the product lifecycle and am
                adept in working on both{" "}
                <b>technical and people facing tasks</b>. Being a software
                engineer in{" "}
                <StyledA
                  href="https://www.tech.gov.sg/"
                  target="_blank"
                  data-replace="GovTech Singapore"
                >
                  <span>GovTech Singapore</span>
                </StyledA>{" "}
                built my foundation and moving on to be a lead product manager
                in{" "}
                <StyledA
                  href="https://www.temasek.com.sg/"
                  target="_blank"
                  data-replace="Temasek
                  International"
                >
                  <span>Temasek International</span>
                </StyledA>{" "}
                allowed me to take on a more leadership and decision making
                role. While in Temasek I have led product teams of over{" "}
                <b>25 cross functional talented individuals</b>, and I have
                learnt how to build high functioning teams with ownership and to
                prioritise team happiness.
              </li>
              <li>
                I have experience in large organisations. Knowing how to
                identify and navigate systems complexities across multiple
                functions such as legal, governance, tax, etc. As my
                responsibilities as a lead product manager, besides the usual{" "}
                <b>
                  stakeholder management, feature prioritization, building
                  strategic roadmaps, finding new use cases, leading user
                  research
                </b>
                , etc, I also regularly{" "}
                <b>
                  pitch projects to senior management and lead change management
                  efforts
                </b>{" "}
                within the organization. My hands often get itchy and I still
                code and am part of the development team making{" "}
                <b>architecture decisions to reviewing PRs</b>.
              </li>
              <li>
                I actively{" "}
                <StyledLink
                  to="/about#endevours"
                  data-replace="organize activities"
                >
                  <span>organize activities</span>
                </StyledLink>{" "}
                and partner with organizations that seek to empower and grow the
                lesser fortunate in the community. I teach coding to kids, and
                have kickstarted the{" "}
                <StyledA
                  href="https://www.tech.gov.sg/singapore-digital-government-journey/diversity-and-inclusion"
                  target="_blank"
                  data-replace="Digital4Good"
                >
                  <span>Digital4Good</span>
                </StyledA>{" "}
                initiative in GovTech. I also lead one of Temasek's{" "}
                <StyledA
                  href="https://www.temasek.com.sg/en/our-community/staff-volunteers"
                  target="_blank"
                  data-replace="T-Touch"
                >
                  <span>T-Touch</span>
                </StyledA>{" "}
                house where we give back to the community through various
                activities. Besides that I constantly give back to my alma
                mater, being a founding member and first Vice President of the
                Singapore University of Technology and Design Alumni
                Association. I have also organized talks and spoken as a guest
                lecturer in{" "}
                <StyledA
                  href="https://www.sutd.edu.sg/"
                  target="_blank"
                  data-replace="Singapore University of Technology and Design"
                >
                  <span>Singapore University of Technology and Design</span>
                </StyledA>{" "}
                and{" "}
                <StyledA
                  href="https://www.ntu.edu.sg/"
                  target="_blank"
                  data-replace="Nanyang Technological University Singapore"
                >
                  <span>Nanyang Technological University Singapore</span>
                </StyledA>{" "}
                covering topics on both product management and software
                development.
              </li>
            </ul>
          </Description>
        </Wrapper>
      </RightBackground>
    </>
  );
};
